<template>
  <div class="Contract_I">
    <div class="capitalNav">
      <div class="capitalLi" style="margin-top:0;"> 
        <div class="capitalLiName">销售项目：</div>
        <div>
          <span @click="Activ(1, 0)" :class="0 == goodsList_value ? 'capitalLiActive' : ''">全部</span>
          <span @click="Activ(1 , item.goods_id)" :class="item.goods_id == goodsList_value ? 'capitalLiActive' : ''" 
            v-for="item in goodsList" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"
          >{{item.goods_name}}</span>
        </div>
      </div>
      <div class="capitalLi" style="margin-top:0;"> 
        <div class="capitalLiName">付款形式: </div>
        <div>
          <span @click="Activ(2, 0)" :class="0 == payForm_value ? 'capitalLiActive' : ''">全部</span>
          <span @click="Activ(2 , item.id)" :class="item.id == payForm_value ? 'capitalLiActive' : ''" 
            v-for="item in payForm" :key="item.id" :label="item.name" :value="item.id"
          >{{item.name}}</span>
        </div>
      </div>
      <div style="display:flex;">
        <div class="NavLi" style="margin-right:30px">
          <div class="NavLiName">合同金额：</div>
          <div class="NavLiIpt" style="display:flex;">
            <el-input type="number" v-model="search.money.min" placeholder="输入具体数值"></el-input>
            <span class="timeSpan" style="margin-top:8px">至</span>
            <el-input type="number" v-model="search.money.max" placeholder="输入具体数值"></el-input>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">创建时间：</div>
          <div class="NavLiIpt">
            <el-date-picker v-model="search.time.min" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
            <span class="timeSpan">至</span>
            <el-date-picker v-model="search.time.max" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
          </div>
          <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
        </div>
      </div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入姓名">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelect">
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
        <el-table-column align="center" prop="customer.username" label="姓名"></el-table-column>
        <el-table-column align="center" prop="customer.mobile" label="联系方式"></el-table-column>
        <el-table-column align="center" prop="goods_name" label="购买项目">
          <template #default="scope" >
            <div v-for="item in scope.row.goods" :key="item">{{item}}</div>
          </template>    
        </el-table-column>
        <el-table-column align="center" prop="pay_price" label="支付金额"></el-table-column>
        <el-table-column align="center" prop="unpaid_money" label="剩余金额"></el-table-column>
        <el-table-column align="center" prop="staff.realname" label="员工"></el-table-column>
        <el-table-column align="center" prop="confirm_status_text" label="重要提醒"></el-table-column>
        <el-table-column label="操作" align="center" width="200" >
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(2,scope.row)"
              >查看   
              </el-button>
            </div>
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(1,scope.row)"
              >编辑   
              </el-button>
            </div>
            <!-- <div class="el_butto rere" >
              <el-button
                type="text"
                class="red"
                @click="HandleDel(scope.row)">删除</el-button>
            </div> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 编辑新增 -->
    <div class="show" v-if="ruleFormRef">
      <div class="rightPanel-background"/>
      <div class="EditNew" style="width:882px">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <div class="headNavs" style="padding: 0 26px" v-if="isRow == 1">
          <el-menu
            :default-active="acndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2">
            <el-menu-item index="1">合同管理</el-menu-item>
            <el-menu-item index="2">跟进记录</el-menu-item>
          </el-menu>
        </div>
        <el-form class="EditNewForm" :class="isRow == 1? 'aa':'kk'" v-if="isAcndex == 1">
          <el-form-item class="EditNewFormBtns gg">
            <div class="EditNewFormItemName">基本信息</div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName" style="margin-bottom:8px"><span class="signSpan">* </span>姓名</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.username" placeholder="请填写客户姓名" disabled></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName" style="margin-bottom:8px"><span class="signSpan">* </span>联系方式</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.mobile" placeholder="请填写客户姓名" disabled></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="contract"> 
              <div>
                <div>所有的订单</div>
                <div>{{Tongji.total}}个</div>
              </div>
              <div>
                <div>未完成订单</div>
                <div>{{Tongji.unfinish}}个</div>
              </div>
              <div>
                <div>已退费的订单</div>
                <div>{{Tongji.refund}}个</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <el-table :data="OrderList" border align="center">
              <el-table-column prop="createtime" label="购买时间" align="center" width="110"></el-table-column>
              <el-table-column  prop="order_no" align="center" width="150" label="订单号"></el-table-column>
              <el-table-column prop="goodsone.goods_name" align="center" width="120" label="购买项目"></el-table-column>
              <el-table-column prop="goodsone.business_username"  width="102" align="center" label="服务商"></el-table-column>
              <el-table-column prop="pay_type_text" align="center" width="90" label="支付方式"></el-table-column>          
              <el-table-column prop="pay_price" align="center" width="90" label="支付金额"></el-table-column>  
              <el-table-column prop="order_status_text" align="center" width="80" label="订单状态"></el-table-column>   
              <el-table-column label="操作" align="center"  width="70">
                <template #default="scope">
                  <div class="mmim" @click="handleEdit(1, scope.row)">查看</div>
                </template>
              </el-table-column>
            </el-table>
            <div class="NavLiReset tj">添加</div> 
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <el-table :data="FileList" border>
              <el-table-column prop="createtime" align="center" label="上传时间"></el-table-column>
              <el-table-column prop="name" align="center" label="文件名称"></el-table-column>
              <el-table-column prop="filesize" align="center" width="100" label="文件大小"></el-table-column>
              <el-table-column prop="staff.realname" align="center" width="100" label="上传者"></el-table-column>
              <el-table-column label="操作" align="center">
                <template #default="scope">
                  <div class="mui">
                    <div class="mmim "> 
                      <a style="color:#1890FF" :href="scope.row.full_filepath" :download="scope.row.name + '.' + scope.row.format">下载</a>
                    </div>
                    <div class="mmim " @click="handleDel(scope.row)">删除</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="NavLiReset tj" @click="AddTo(2)">添加</div>
          </el-form-item>
        </el-form>
        <div v-else>
          <div class="EditNewForm">
            <div class="EditNewFormBtns">
              <div class="EditNewFormItemName">全部进程信息</div>
            </div>
            <div class="EditNewFormBtns TRRE" v-if="Flow_list.length != 0">
              <el-scrollbar height="100%">
                <div id="time-line">
                  <div class="item" v-for="item in Flow_list" :key="item.index">
                    <div class="ytyt">
                      <div class="item-index">{{ item.createtime }}</div>
                      <div class="item-content">{{ item.info }}</div>
                      <img class="Rtt_im22g" v-for="item in item.imgs_url" :key="item" :label="item" :value="item" :src="item"   />
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
            <div class="EditNewFormBtns ffff" v-else>
              <div class="EditNewFormItemName">暂无信息</div>
            </div>
            <div class="EditNewFormBtns">
              <div class="EditNewFormItemName">文字记录</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="Info_desc" placeholder="请将每次沟通、维护、跟单、咨询等相关信息记录在这里，为了提高效率" :disabled="control"></el-input>
              </div>
            </div>
            <div class="EditNewFormBtns gggg">
              <div class="EditNewFormItemName">图片线索（选填）:</div>
              <div class="EditNewFormItemIpt">
                <div class="Picture">
                  <el-upload
                    :action="upload"
                    :headers="heads"
                    list-type="picture-card"
                    :file-list="fileList1"
                    :on-success="handleSuccess1"
                    :on-exceed="handleExceed1"
                    :on-preview="handlePreview1">
                    <i class="el-icon-plus"></i>
                    <div class="elsff">上传图片</div>
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="EditNewFormBtns">
              <div class="FormBtns">
                <div class="FormBtnsQuery" @click="BtnProcess()">保存</div>
                <div class="FormBtnsClose" @click="Close()">取消</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 导入资料 -->
    <el-dialog title="导入资料" v-model="editVisible7" width="31%">
        <el-form ref="form" label-width="120px">
          <div class="DDGGD">
          <el-form-item label="选择导入文件：" style="margin-bottom:5px">
            <div class="el-form-item__content">
              <el-upload
                  accept=".xls,.xlsx"
                  :action="upload"
                  :headers="heads"
                  :multiple="false"
                  :file-list="fileList2"
                  :on-success="UP_Success4"
                  :on-remove="fileRemove">
                  <el-button size="small" type="primary">选择文件</el-button>
                  <div class="el-upload__tip">一次只能上传一个xls/xlsx文件，且不超过10M</div>
              </el-upload>
          </div>
          </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <!-- <el-button v-if="isSure == 1" type="primary" @click="File_Sure2()">确 认</el-button> -->
            <el-button v-if="isSure == 2" type="primary" @click="File_Sure()">确 认22</el-button>
          </span>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return{
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      upload2:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      goodsList_value: 0,
      payForm_value: 0,
      acndex:1,
      isAcndex:'',
      isRow:'',
      isSure:'',
      total:'',
      editId:'',
      titleMath:'',
      WDJ_id:'',
      Flie_name:'',
      Flie_ffff:'',
      Info_desc:'',
      goodsList:[],
      payForm:[{id:'all',name:'全款'},{id:'part',name:'部分'}],
      tableData:[],
      OrderList:[],
      FileList:[],
      fileList1:[],
      Row_Eurl2:[],
      Row_Eurl:[],
      Row_Eurl3:[],
      Flow_list:[],
      page: {currentPage: 1,pageSize: 10},// 分页
      search: {  //搜索参数
        goods_id:'',
        pay_form:'',
        keyword:'',
        time: {
          min:"",
          max:"",
        },
        money: {
          min:"",
          max:"",
        },
      },
      Row:{username:'',mobile:''},
      Tongji:{},
      ruleFormRef:false,
      editVisible7:false
    }
  },
  mounted(){
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.staff()
    that.loadTable()
  },
  methods:{
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/customer/contract/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    staff(){
      var that = this
      axios({ method: 'post', url: '/admin/ajax/goodsList', 
      }).then(function (res) {
        that.goodsList = res.data.data
      })  
    },
    Activ(e,id){
      if(e == 1){
        this.goodsList_value = id
        this.search.goods_id = id
      }else if(e == 2){
        this.payForm_value = id
        this.search.pay_form = id
      }
    },
    AddTo(e){
      this.editVisible7 = true
      if(e == 1){
        this.isSure = 1
      }else{
        this.isSure = 2
      }
    },
    UP_Success2(e) {  //图片上传成功
      if(this.isSure == 1){
        this.Row_Eurl.push(e.data.url)
      }else{
        this.Row_Eurl2.push(e.data.url)
      }
    },
    File_Sure(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/customer/contract/addFile',
        data: {
          row:{
            customer_id:that.WDJ_id,
            name:that.Flie_name,
            filepath:that.Flie_ffff,
          }
        },
      }).then(function (res) {
        if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editVisible7 = false
            that.kk();
          }
      })
    },
    HandleDel(data) {  //删除
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
          axios({
          method: 'DELETE',
          url: '/admin/customer/contract/delFile',
          data: {
            id: data.id
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    BtnSearch(e){
      if(e == false){
        this.search = {
          goods_id:'',
          pay_form:'',
          keyword:'',
          time: {
            min:"",
            max:"",
          },
          money: {
            min:"",
            max:"",
          },
        }
      }
      this.loadTable()
    },
    HandleEdit(e,data){
      var that = this
      that.editId = data.id
      that.ruleFormRef = true
      if(e == 1){
        that.titleMath = '编辑订单'
        that.isAcndex = 1
        that.isRow = 1
      }else if(e == 2){
        that.titleMath = '查看订单'
        that.isRow = ''
        that.isAcndex = 1
      }
      axios.get('/admin/customer/contract/read',{   // 线索详情
        params:{
          id: that.editId
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else { 
          that.Row = res.data.data.customer
          that.WDJ_id = res.data.data.customer.id
          that.Tongji = res.data.data.tongji
          that.OrderList = res.data.data.orderList
          that.FileList = res.data.data.fileList
        }
      })
    },
    handleSelect2(key){
      if(key == 1){
        this.isAcndex = 1
      }else if(key == 2){
        this.isAcndex = 2
        this.getFlow()
      }
    },
    getFlow(){
      var that = this
      axios.get('/admin/customer/flow/read',{   // 线索 进程信息
          params:{
            customer_id:that.editId,
            type: "contract", 
            source_id: that.editId
          }
      }).then(function (res) {
        that.Flow_list = res.data.data.flow_list
      }) 
    },
    handleDel(data){
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
          axios({
          method: 'DELETE',
          url: '/admin/customer/contract/delFile',
          data: {
            id: data.id
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.kk();
          }
        })
      })
    },
    BtnProcess(){   //进程提交
      var that = this
      axios({
        method: 'post',
        url: '/admin/customer/flow/add', 
        data: {
          row:{
            customer_id: that.editId, //线索ID，销售线索时必填
            info: that.Info_desc, //进程的信息
            type: "contract", //类型:leads=线索,customer=客户,order=订单,contract=合同
            source_id: that.editId, //当前类型所在列表ID
            images: that.Row_Eurl3
          }
        },
      }).then(function (res) {
        if(res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.getFlow()
          that.Info_desc = ''
          that.fileList1 = []
        }
      })  
    },
    kk(){
      var that = this
      axios.get('/admin/customer/contract/read',{   // 线索详情
        params:{
          id: that.editId
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else { 
          that.OrderList = res.data.data.orderList
          that.FileList = res.data.data.fileList
        }
      })
    },
    UP_Success4 (response, file, fileLis) {
      var that = this
      that.Flie_name = file.name
      that.Flie_ffff = response.data.url
    },
    handleSuccess1(e) {
      var that = this
      that.Row_Eurl3 = e.data.url
    },
    Close(){
      this.ruleFormRef = false
    }
  }
}
</script>
<style scoped>
.gg{
  font-weight: bold;
  margin-bottom: 5px;
}
.contract{
  display: flex;
  justify-content: space-around;
  background-color: #FAFAFA;
  border-radius: 6px;
  width: 97%;
  padding: 20px 0;
  text-align: center;
  color: #262626;
}
.tj{
  margin-top: 30px;
  width: 97%;
}
.kk{
  padding: 0 34px
}
.aa{
  padding: 26px
}
</style>