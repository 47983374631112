<template>
  <div class="Customer_I">
    <div class="capitalNav">
      <div class="capitalLi" style="margin-top:0;"> 
        <div class="capitalLiName">客户来源：</div>
        <span :class="0 == Customer_worth_value ? 'capitalLiActive': ''" @click="Activ(1, 0)">全部</span>
        <span :class="item.value == Customer_worth_value ? 'capitalLiActive': ''" @click="Activ(1 , item.value)" 
          v-for="item in Customer_worth" :key="item.value" :label="item.content" :value="item.value"
        >{{item.content}}</span>
        <span @click="Clue(1)" style="color: #0081FF;">管理客户来源</span>
      </div>
      <div class="capitalLi"> 
        <div class="capitalLiName">客户需求：</div>
        <span :class="0 == leads_demand_value ? 'capitalLiActive': ''" @click="Activ(2, 0)">全部</span>
        <div class="mmmmm">
          <span :class="item.goods_id == leads_demand_value ? 'capitalLiActive': ''" @click="Activ(2 , item.goods_id)" 
            v-for="item in leads_demand" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"
          >{{item.goods_name}}</span>
        </div>
      </div>
      <div class="capitalLi"> 
        <div class="capitalLiName">客户价值：</div>
        <span :class="0 == leads_worth_value ? 'capitalLiActive': ''" @click="Activ(3, 0)">全部</span>
        <span :class="item.value == leads_worth_value ? 'capitalLiActive': ''" @click="Activ(3 , item.value)" 
          v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"
        >{{item.content}}</span>
        <span @click="Clue(2)" style="color: #0081FF;">管理客户价值</span>
      </div>
      <div class="capitalLi"> 
        <div class="capitalLiName">客户标签：</div>
        <span :class="0 == leads_customer_tag_value ? 'capitalLiActive': ''" @click="Activ(4, 0)">全部</span>
        <span :class="item.value == leads_customer_tag_value ? 'capitalLiActive': ''" @click="Activ(4 , item.value)" 
          v-for="item in leads_customer_tag" :key="item.value" :label="item.content" :value="item.value"
        >{{item.content}}</span>
        <span @click="Clue(3)" style="color: #0081FF;">管理客户标签</span>
      </div>
      <div>
        <div class="NavLi">
          <div class="NavLiName">创建时间：</div>
          <div class="NavLiIpt">
            <el-date-picker v-model="search.time.min" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
            <span class="timeSpan">至</span>
            <el-date-picker v-model="search.time.max" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
          </div>
          <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
        </div>
      </div>
    </div>

  <div class="TablesList">
    <div class="TablesADD">
      <div class="T_sele dasdas ddsdaasd" style="width:150px">
        <el-select v-model="type" placeholder="请选择线索" @change="Chang_ses">
            <el-option v-for="item in MMD" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="NavLiQuery" @click="showAdd()" v-if="TextMoMo('customer:leads:add')"><i class="el-icon-plus"></i>创建客户</div>
      <div class="NavLiReset" @click="export2Excel()" v-if="TextMoMo('customer:leads:claim')" v-show ="type == 'common'"><i class="el-icon-upload2"></i>导出</div>
      <div class="NavLiReset" @click="editVisible_DEX = true" v-if="TextMoMo('customer:leads:import')"><i class="el-icon-download"></i>导入</div>
      <div class="NavLiReset" @click="batch(1)" v-if="TextMoMo('customer:leads:callback')" v-show ="type == 'self'">
        <img class="Img_ti" src="~@/assets/img/tuihui.png" alt="">批量退回</div>
      <div class="NavLiReset" @click="batch(2)" v-if="TextMoMo('customer:leads:claim')" v-show ="type == 'common'"><i class="el-icon-document"></i>批量认领</div>
      <div class="NavLiDelte" @click="batch(3)"><i class="el-icon-delete"></i>批量删除</div>
      <div class="setUp" @click="batch(4)"><i class="el-icon-s-tools"></i></div>
      <div class="TableIpt"> 
        <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入姓名">
        <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
      </div>
    </div>
    <el-table
      :data="tableData"
      class="table"
      ref="multipleTable"
      header-cell-class-name="table-header"
      @selection-change="Selection">
      <el-table-column type="selection"  width="55"  align="center"></el-table-column>
      <el-table-column prop="id"  label="ID" width="55" align="center"></el-table-column>
      <el-table-column align="center" prop="username" label="姓名"></el-table-column>
      <el-table-column align="center" prop="mobile" label="联系方式"></el-table-column>
      <el-table-column align="center" prop="source" label="客户来源"></el-table-column>
      <el-table-column align="center" prop="demand" label="需求项目">
          <template #default="scope" >
            <div class="elelels" >
              <span v-for="item in scope.row.goods" :key="item" :label="item" :value="item">{{item}}，</span>
            </div>
          </template>          
      </el-table-column>
      <el-table-column align="center" prop="worth" label="客户价值"></el-table-column>
      <el-table-column align="center" prop="createtime" label="创建时间"></el-table-column>
      <el-table-column align="center" prop="updatetime" label="更新时间"></el-table-column>
      <el-table-column align="center" label="操作" width="200" v-if="type == 'common'">
        <template #default="scope">
          <div class="el_butto">
            <el-button type="text" @click="HandlClaim(scope.row)" v-if="TextMoMo('customer:leads:columns')">认领</el-button>
          </div>
          <div class="el_butto rere" >
            <el-button type="text" class="red" @click="HandleDel(scope.row)" v-if="TextMoMo('workbench:worknav:nav:add')" >删除</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="300" v-if="type == 'self'">
        <template #default="scope">
          <div class="el_butto">
            <el-button type="text" 
            v-if="TextMoMo('customer:leads:edit')" @click="HandleEdit(scope.row)">编辑</el-button>
          </div>
          <div class="el_butto">
            <el-button type="text" @click="HanUpgrade(scope.row)">升级</el-button>
          </div>
          <div class="el_butto">
            <el-button type="text" @click="HandReturn(scope.row)">退回</el-button>
          </div>
          <div class="el_butto rere">
            <el-button
            type="text"
            class="red" v-if="TextMoMo('workbench:worknav:nav:add')" 
            @click="HandleDel(scope.row)"  
            >删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>

    <!-- 编辑新增 -->
    <div class="show" v-if="ruleFormRef">
      <div class="rightPanel-background"/>
      <div class="EditNew" :class="isAcndex == 3 ? 'EditNewS':''">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <div class="headNavs" style="padding: 0 26px" v-if="isRow == 1">
          <el-menu
            :default-active="acndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect">
            <el-menu-item index="1">客户管理</el-menu-item>
            <el-menu-item index="2">跟进记录</el-menu-item>
          </el-menu>
        </div>
        <el-form class="EditNewForm" :model="Row" :rules="rules" ref="Row" v-if="isAcndex == 1">
          <el-form-item class="EditNewFormItem" prop="username">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>姓名：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.username" placeholder="请输入姓名"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="mobile">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>联系方式：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.mobile" placeholder="请输入联系方式" ></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>微信号 （选填）</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.weixin" placeholder="请填写微信号"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>工作单位（选填）</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.company" placeholder="请填写工作单位" ></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="sex">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>性别：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.sex" placeholder="请选择性别">
                <el-option v-for="item in SEX" :key="item.id" :label="item.name" :value="item.id" ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">来源信息</div>
          </el-form-item>

          <el-form-item class="EditNewFormItem" prop="source_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>客户来源</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.source_id" placeholder="请选择客户来源">
                <el-option v-for="item in Customer_worth" :key="item.value" :label="item.content" :value="item.value" ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="worth_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>客户价值</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.worth_id" placeholder="请选择需求来源">
                <el-option v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="expect_time">
            <div class="EditNewFormItemName" style="margin-bottom: 1px;"><span class="signSpan">* </span>办理时间（选填）</div>
            <div class="EditNewFormItemIpt">
              <el-date-picker v-model="Row.expect_time" type="date" placeholder="选择时间" :clearable="false"></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="service_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>服务对象
              <span class="Modification" @click="Clue(10)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.service_id" placeholder="请选择服务对象">
                <el-option v-for="item in leads_customer_service" :key="item.value" :label="item.content" :value="item.value"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="tag_ids">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>客户标签（可多选）：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.tag_ids" multiple placeholder="请选择客户标签">
                <el-option v-for="item in leads_customer_tag" :key="item.value" :label="item.content" :value="item.value"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="goods_ids">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>需求项目（可多选）：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.goods_ids" multiple placeholder="请选择需求项目">
                <el-option v-for="item in leads_demand" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">描述（选填）：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="textarea" v-model="Row.description" placeholder="请输入联系方式" ></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">图片线索（选填）</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  :action="upload"
                  :headers="heads"
                  list-type="picture-card"
                  :file-list="fileList"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="resetForm == 'xinjian'" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="resetForm == 'baocun'" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>

        <div v-else-if="isAcndex == 2">
          <div class="EditNewForm">
            <div class="EditNewFormBtns">
              <div class="EditNewFormItemName">全部进程信息</div>
            </div>
            <div class="EditNewFormBtns TRRE" v-if="Flow_list.length != 0">
              <el-scrollbar height="100%">
                <div id="time-line">
                  <div class="item" v-for="item in Flow_list" :key="item.index">
                    <div class="ytyt">
                      <div class="item-index">{{ item.createtime }}</div>
                      <div class="item-content">{{ item.info }}</div>
                      <img class="Rtt_im22g" v-for="item in item.imgs_url" :key="item" :label="item" :value="item" :src="item"   />
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
            <div class="EditNewFormBtns ffff" v-else>
              <div class="EditNewFormItemName">暂无信息</div>
            </div>
            <div class="EditNewFormBtns">
              <div class="EditNewFormItemName">文字记录</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="Info_desc" placeholder="请将每次沟通、维护、跟单、咨询等相关信息记录在这里，为了提高效率" ></el-input>
              </div>
            </div>
            <div class="EditNewFormBtns gggg">
              <div class="EditNewFormItemName">图片线索（选填）:</div>
              <div class="EditNewFormItemIpt">
                <div class="Picture">
                  <el-upload
                    :action="upload"
                    :headers="heads"
                    list-type="picture-card"
                    :file-list="fileList1"
                    :on-success="handleSuccess1"
                    :on-exceed="handleExceed1"
                    :on-preview="handlePreview1">
                    <i class="el-icon-plus"></i>
                    <div class="elsff">上传图片</div>
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="EditNewFormBtns">
              <div class="FormBtns">
                <div class="FormBtnsQuery" @click="BtnProcess()">保存</div>
                <div class="FormBtnsClose" @click="Close()">取消</div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="isAcndex == 3">
          <el-form class="EditNewForm" :model="Row" :rules="rules" ref="Row">
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>每日线索认领量（条）
              <span class="Modification" @click="Clue(9)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.claim_day" placeholder="每人每天认领线索上限">
                <el-option v-for="item in claimday" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>单次线索认领量（条）
              <span class="Modification" @click="Clue(4)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.claim_num" placeholder="每人每天认领线索上限">
                <el-option v-for="item in claimone" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>进程更新对低频（天）
              <span class="Modification" @click="Clue(5)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.claim_one" placeholder="低于设置时间将回收销售线索">
                <el-option v-for="item in flowmin" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>每周数据导出频率（次）
              <span class="Modification" @click="Clue(6)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.export_one" placeholder="每周每人可以带出数据次数">
                <el-option v-for="item in exportweek" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>每次数据导出数量（条）
              <span class="Modification" @click="Clue(7)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.export_week" placeholder="每人每次可以导出数量的上限">
                <el-option v-for="item in exportone" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>认领权限设置
              <span class="Modification" @click="Clue(8)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.flow_min" placeholder="同一人认领同一线索次数">
                <el-option v-for="item in claimnum" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div class="FormBtnsQuery" @click="BtnEdit1()">确定</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
        </div>
      </div>
    </div>
    <!--添加线索来源-->
    <el-dialog :title="titleMath" v-model="dialogVisible3" width="36%">
      <div class="Tabbl">
        <el-table
        :data="ClueData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete(scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="YADD" v-if="isInfo == 'neirong'" @click="CreateAdd(AFF)"><i class="el-icon-plus"></i>创建客户来源</div>
      <div class="YADD" v-if="isInfo == 'jiazhi'" @click="CreateAdd(AFF)"><i class="el-icon-plus"></i>创建客户价值</div>
      <div class="YADD" v-if="isInfo == 'biaoqian'" @click="CreateAdd(AFF)"><i class="el-icon-plus"></i>创建客户标签</div>
      <div class="YADD" v-if="isInfo == 'quanbu'" @click="CreateAdd(AFF)"><i class="el-icon-plus"></i>创建全部线索</div>
      <div class="YADD" v-if="isInfo == 'fuwu'" @click="CreateAdd(AFF)"><i class="el-icon-plus"></i>创建服务对象</div>
      <div class="EditNewFormBtns">
        <div class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="determine()">确 定</el-button>
        </div>
      </div>  
    </el-dialog>

    <!-- 导入 -->
    <el-dialog title="导入文件" v-model="editVisible_DEX" width="31%" class="">
      <el-form label-width="120px">
        <el-form-item label="模板：">
          <el-button type="primary"> <a style="color:#fff" href="./w3school_logo2.xlsx" download="w3school_logo2.xlsx">下载模板</a></el-button>
        </el-form-item>
        <el-form-item label="选择导入文件：">
          <el-upload
            accept=".xls,.xlsx"
            :action="upload"
            :headers="heads"
            :multiple="false"
            :file-list="fileList2"
            :on-success="UP_Success3"
            :on-remove="fileRemove">
            <el-button size="small" type="primary">选择文件</el-button>
            <div class="el-upload__tip">一次只能上传一个xls/xlsx文件，且不超过10M</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="可见员工：">
          <el-select v-model="File_Yid" multiple placeholder="请选择可见员工">
            <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="可见部门：">
          <el-cascader
            style="width:100%"
            :options="DementList"
            v-model="File_Bid"
            :props="{ multiple: true, checkStrictly: true, label : 'name', value: 'id'}"
            @change="Handmuiksd2"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <div class="dialog-footer">
            <el-button type="primary" @click="File_Sure()">确 定</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../../components/utils.vue";
export default {
  data(){
    return{
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      multipleSelection_id:'',
      type:'self',
      total:'',
      titleMath:'',
      resetForm:'',
      isAcndex:'',
      editId:'',
      isShow:'',
      Info_desc:'',
      Row_Eurl2:'',
      File:'',
      File_Yid:'',
      File_Bid:'',
      fieldKey:'',
      content:'',
      acndex:1,
      Retrieve:'',
      isRow:'',
// ---------------------------------------------------
      leads_demand_value:0,
      Customer_worth_value:0,
      leads_worth_value:0,
      leads_customer_tag_value:0,
      dialogVisible3e:0,
      Customer_worth:[],
      leads_worth:[],
      leads_customer_tag:[],
      leads_customer_service:[],
      leads_demand:[],
      fileList:[],
      fileList1:[],
      Flow_list:[], 
      ClueData:[],
      fileList2:[],
      multipleSelection:[],
      DementList:[],
      StaFFList:[],
// ------------------- 全部线索-----------------------------
      claimday:[],
      claimone:[],
      flowmin:[],
      exportweek:[],
      exportone:[],
      claimnum:[],
      ConfigList:[],
      tableData:[],
      MMD:[{id:'self',name:'我的客户'},{id:'common',name:'公海客户'}],
      SEX:[{name:'男',id:'1'},{name:'女',id:'2'}],
      ruleFormRef:false,
      editVisible_DEX:false,
      dialogVisible3:false,
      page: {currentPage: 1,pageSize: 10},// 分页
      search: {  //搜索参数
        source_id:'',
        worth_id:'',
        demand_id:'',
        keyword: "",
        goods_id:'',
        tag_id:'',
        time: {
          min:"",
          max:"",
        },
      },
      rules:{
        username:[{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobile:[{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        sex:[{ required: true, message: '请选择性别', trigger: 'change' }],
        source_id:[{ required: true, message: '请选择客户来源', trigger: 'change' }],
        worth_id:[{ required: true, message: '请选择需求来源', trigger: 'change' }],
        expect_time:[{ required: true, message: '请选择时间', trigger: 'change' }],
        service_id:[{ required: true, message: '请选择服务对象', trigger: 'change' }],
        tag_ids:[{ required: true, message: '请选择客户标签', trigger: 'change' }],
        goods_ids:[{ required: true, message: '请选择需求项目', trigger: 'change' }]
      },
      Row:{
        username:'',
        mobile:'',
        weixin:'',
        company:'',
        sex:'',
        source_id:'',
        worth_id:'',
        expect_time:'',
        service_id:'',
        tag_ids:[],
        goods_ids:[],
        description:'',
        images:[],
        staff_ids:[],
        department_ids:[],
        type:'',

      },
    }
  },
  mounted(){
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()
    that.staff()
  },
  methods:{
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/customer/customer/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          type: that.type,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //创建
    showAdd(){
      var that = this
      that.isRow = ''
      that.ruleFormRef = true;
      that.titleMath = '创建客户'
      that.resetForm = 'xinjian'
      that.isAcndex = 1
      that.staff()
      that.Row = {}
    },
    BtnAdd (){
      var that = this
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'post',
            url: '/admin/customer/customer/add',
            data: {
              row: {
                type:that.type,
                username: that.Row.username,
                mobile: that.Row.mobile,
                sex: that.Row.sex,
                company: that.Row.company,
                weixin: that.Row.weixin,
                expect_time: that.Row.expect_time,
                service_id: that.Row.service_id,
                source_id: that.Row.source_id,
                goods_ids: that.Row.goods_ids,
                worth_id: that.Row.worth_id,
                tag_ids: that.Row.tag_ids,
                description: that.Row.description,
                images: that.Row.images,
                power:{
                  staff_ids: that.Row.staff_ids,
                  department_ids: that.Row.department_ids,
                }
              },
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef = false
              that.loadTable();
            }
          }) 
        }
      })
    },
    //编辑
    HandleEdit(data){
      var that = this
      that.staff()
      that.ruleFormRef = true;
      that.resetForm = 'baocun';
      that.titleMath = '编辑客户'
      that.editId = data.id
      that.isAcndex = 1
      that.isRow = 1
      this.getflow()
      axios.get('/admin/customer/customer/read', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row.username = res.data.data.row.username
          that.Row.mobile = res.data.data.row.mobile
          that.Row.sex = res.data.data.row.sex
          that.Row.company = res.data.data.row.company
          that.Row.weixin = res.data.data.row.weixin
          that.Row.expect_time = res.data.data.row.expect_time
          that.Row.service_id = res.data.data.row.service_id
          that.Row.source_id = res.data.data.row.source_id
          that.Row.worth_id = res.data.data.row.worth_id
          that.Row.description = res.data.data.row.description


          var brr = res.data.data.row.tag_obj
          var Ner_brr = []
          for (let i = 0; i < brr.length; i++) {
            Ner_brr[i]  =  parseInt(brr[i]);
          }
          that.Row.tag_ids = Ner_brr

          var arr =  res.data.data.row.goods_obj
          var Ner_arr = []
          for (let i = 0; i < arr.length; i++) {
            Ner_arr[i]  =  parseInt(arr[i]);
          }
          that.Row.goods_ids = Ner_arr
          //图片
          let fileArr = [];
          let fileObj = {};
          fileObj.name = "file";
          fileObj.url = res.data.data.row.images;
          fileArr.push(fileObj);
          that.fileList = fileArr
        }
      })
    },
    BtnEdit(){
      var that = this
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'PUT',
            url: '/admin/customer/customer/edit',
            data: {
              id:that.editId,
              row: {
                type : that.type,
                username: that.Row.username,
                mobile: that.Row.mobile,
                sex: that.Row.sex,
                company: that.Row.company,
                weixin: that.Row.weixin,
                expect_time: that.Row.expect_time,
                service_id: that.Row.service_id,
                source_id: that.Row.source_id,
                goods_ids: that.Row.goods_ids,
                worth_id: that.Row.worth_id,
                tag_ids: that.Row.tag_ids,
                description: that.Row.description,
                images: that.Row.images,
              },
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef = false
              that.loadTable();
            }
          }) 
        }
      })
    },
    //删除
    HandleDel(data) {
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
        method: 'DELETE',
        url: '/admin/customer/customer/del',
        data: {
          ids: arr
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
      })
    },
    getflow(){ // 线索 进程信息
      var that = this
      axios.get('/admin/customer/flow/read',{   
          params:{
            customer_id: that.editId
          }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else { 
          that.Flow_list = res.data.data.flow_list
        }
      }) 
    },
    handleSelect(key){
      if(key == 1){
        this.isAcndex = 1
      }else if(key == 2){
        this.isAcndex = 2
      }
    },
    //认领
    HandlClaim(data){
      var that = this 
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要认领吗？", "提示", {  
          type: "primary"
        }).then(() => {
        axios({
          method: 'POST',
          url: '/admin/customer/customer/claim', 
          data: {
            ids:arr
          },
        }).then(function (res) {
          that.$message.success(res.data.msg);
          that.loadTable()        
        })  
      }) 
    },
    //升级
    HanUpgrade(data){
      var that = this 
      that.$confirm("确定要升级吗？", "提示", {  
          type: "primary"
        }).then(() => {
        that.$emit('OffBtn',that.Retrieve = data.id)
      }) 
    },
    saveEdit(){
      var that = this 
      var arr = []
      arr.push(that.dataId)
      axios({
        method: 'post',
        url: '/admin/customer/leads/upgrade', 
        data: {
          ids:arr,
          worth_id:that.row.worthId
        },
      }).then(function (res) {
        that.$message.success(res.data.msg);
        that.editVisible = false
        that.loadTable()
      })
    },
    //退回
    HandReturn(data){
      var that = this 
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要退回吗？", "提示", {
          type: "primary"
      }).then(() => {
        axios({
          method: 'post',
          url: '/admin/customer/customer/callback', 
          data: {
            ids: arr
          },
        }).then(function (res) {
          that.$message.success(res.data.msg);
          that.loadTable()        
        })             
      })  
    },
    //批量
    batch(e){
      var that = this 
      if( e == 1 ){
        if (that.multipleSelection_id == '') {  //批量退回
          that.$message.warning('请勾选信息');
          return
        } else {
          that.$confirm("确定要批量退回吗？", "提示", {
              type: "primary"
          }).then(() => {
            axios({
              method: 'post',
              url: '/admin/customer/customer/callback', 
              data: {
                ids: that.multipleSelection_id
              },
            }).then(function (res) {
              if(res.data.code == 1){
                that.$message.success(res.data.msg);
                that.loadTable()  
              }else{
                that.$message.warning(res.data.msg);
              }
            })  
          })
        }
      }else if( e == 2 ){
        if (that.multipleSelection_id == '') {     //批量认领
            that.$message.warning('请勾选信息');
            return
          } else {
          that.$confirm("确定要批量认领吗？", "提示", {  
              type: "primary"
            }).then(() => {
            axios({
              method: 'POST',
              url: '/admin/customer/customer/claim', 
              data: {
                ids:that.multipleSelection_id
              },
            }).then(function (res) {
              if(res.data.code == 1){
                that.$message.success(res.data.msg);
                that.loadTable()  
              }else{
                that.$message.warning(res.data.msg);
              }     
            })  
          })
        }
      }else if( e == 3 ){
        if (that.multipleSelection_id == '') {      //批量删除
          that.$message.warning('请勾选信息');
        } else {
          that.$confirm("确定要删除吗？", "提示", {
            type: "primary"
        }).then(() => {
          axios({
            method: 'delete',
            url: '/admin/customer/customer/del',
            data: {
              ids: that.multipleSelection_id
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.loadTable()
              }
            }) 
          })
        }
      }else if( e == 4 ){
        that.ruleFormRef = true
        that.isAcndex = 3
        that.titleMath = '全部线索管理'
        that.quanbu()
        axios.get('/admin/customer/config/index',{   // 全部部门列表
            params:{
              type: 'leads'
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
            that.ConfigList = res.data.data.config
          }
        })
      }
    },
    //列表搜索
    Activ(e, id) {
      var that = this
      if (e == 1) {
        that.Customer_worth_value = id
        that.BtnSearch()
      } else if (e == 2) {
        that.leads_demand_value = id
        that.BtnSearch()
      } else if (e == 3) {
        that.leads_worth_value = id
        that.BtnSearch()
      } else if (e == 4) {
        that.leads_customer_tag_value = id
        that.BtnSearch()
      } 
    },
    //搜索
    BtnSearch(e) {
      var that = this
      let time = ''
      let time2 = ''
      that.page.currentPage = 1;
      if( e == false){
        that.search = {
          source_id:'',
          worth_id:'',
          demand_id:'',
          keyword: "",
          goods_id:'',
          tag_id:'',
          time: {
            min:"",
            max:"",
          },
        }
      }
      if (that.search.time.min != '' ) {
        time = that.formatDate(that.search.time.min)
      }
      if (that.search.time.max != '' ) {
        time2 = that.formatDate(that.search.time.max)
      }
      axios({
        method: 'post',
        url: '/admin/customer/customer/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          type: that.type,
          filter: {
            source_id: that.Customer_worth_value,
            worth_id: that.leads_worth_value,
            demand_id: that.leads_demand_value,
            keyword: that.search.keyword,
            goods_id: that.leads_demand_value,
            tag_id: that.leads_customer_tag_value,
            time: {
              min: time,
              max: time2,
            },
          },
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //添加线索
    Clue(e){
      this.dialogVisible3 = true
      this.dialogVisible3e = e
      console.log( this.dialogVisible3e );
      if( e == 1 ){
        this.ClueData = this.Customer_worth
        this.titleMath = '添加客户来源'
        this.isInfo = 'neirong'
        this.AFF = 1
      }else if( e == 2 ){
        this.ClueData = this.leads_worth
        this.titleMath = '添加客户价值'
        this.isInfo = 'jiazhi'
        this.AFF = 2
      }else if( e == 3 ){
        this.ClueData = this.leads_customer_tag
        this.titleMath = '添加客户标签'
        this.isInfo = 'biaoqian'
        this.AFF = 3
      }else if( e == 4 ){
        this.ClueData = this.claimone
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 4
      }else if( e == 5 ){
        this.ClueData = this.flowmin
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 5
      }else if( e == 6 ){
        this.ClueData = this.exportweek
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 6
      }else if( e == 7 ){
        this.ClueData = this.exportone
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 7
      }else if( e == 8 ){
        this.ClueData = this.claimnum
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 8
      }else if( e == 9 ){
        this.ClueData = this.claimday
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 9
      }else if( e == 10 ){
        this.ClueData = this.leads_customer_service
        this.titleMath = '服务对象内容管理'
        this.isInfo = 'fuwu'
        this.AFF = 10
      }
    },
    CreateAdd(e){
      var that = this
      var srt = {
        content: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      var srt2 = {
        content: "10",
        status: 'normal',
        type: "custom",
        value:0,
      }
      if( e == 1 ){
        var ATT = that.Customer_worth
        ATT.push(srt)
        that.ClueData = ATT
      }else if( e == 2 ){
        var ADD = that.leads_worth
        ADD.push(srt)
        that.leads_worth = ADD
      }else if( e == 3){
        var ABB = that.leads_customer_tag
        ABB.push(srt)
        that.leads_customer_tag = ABB
      }else if( e == 4){
        var ACC = that.claimone
        ACC.push(srt2)
        that.claimone = ACC
      }else if( e == 5){
        var AEE = that.flowmin
        AEE.push(srt2)
        that.flowmin = AEE
      }else if( e == 6){
        var AFF = that.exportweek
        AFF.push(srt2)
        that.exportweek = AFF
      }else if( e == 7){
        var AGG = that.exportone
        AGG.push(srt2)
        that.exportone = AGG
      }else if( e == 8){
        var AHH = that.claimnum
        AHH.push(srt2)
        that.claimnum = AHH
      }else if( e == 9){
        var AJJ = that.claimday
        AJJ.push(srt2)
        that.claimday = AJJ
      }else if( e == 10){
        var AJJ = that.leads_customer_service
        AJJ.push(srt2)
        that.leads_customer_service = AJJ
      }
    },
    //导航删除
    haeDete(data){
      console.log(data);
      var that = this 
      let valueId = data.value
      var ATT = that.Customer_worth
      var ADD = that.leads_worth
      var ABB = that.leads_customer_tag
      var ACC = that.claimone
      var AEE = that.flowmin
      var AFF = that.exportweek
      var AGG = that.exportone
      var AHH = that.claimnum
      var AJJ = that.claimday
      var Akk = that.leads_customer_service
      if (data.type == 'default') {
      } else {
        if(that.dialogVisible3e == 1){
          let index = ATT.findIndex(obj => obj.value  === valueId)
          ATT.splice(index, 1)
          that.Customer_worth = ATT
        }else if(that.dialogVisible3e == 2){
          let index = ADD.findIndex(obj => obj.value  === valueId)
          ADD.splice(index, 1)
          that.leads_worth = ADD
        }else if(that.dialogVisible3e == 3){
          let index = ABB.findIndex(obj => obj.value  === valueId)
          ABB.splice(index, 1)
          that.leads_customer_tag = ABB
        }else if(that.dialogVisible3e == 4){
          let index = ACC.findIndex(obj => obj.value  === valueId)
          ACC.splice(index, 1)
          that.claimone = ACC
        }else if(that.dialogVisible3e == 5){
          let index = AEE.findIndex(obj => obj.value  === valueId)
          AEE.splice(index, 1)
          that.flowmin = AEE
        }else if(that.dialogVisible3e == 6){
          let index = AFF.findIndex(obj => obj.value  === valueId)
          AFF.splice(index, 1)
          that.exportweek = AFF
        }else if(that.dialogVisible3e == 7){
          let index = AGG.findIndex(obj => obj.value  === valueId)
          AGG.splice(index, 1)
          that.exportone = AGG
        }else if(that.dialogVisible3e == 8){
          let index = AHH.findIndex(obj => obj.value  === valueId)
          AHH.splice(index, 1)
          that.claimnum = AHH
        }else if(that.dialogVisible3e == 9){
          let index = AJJ.findIndex(obj => obj.value  === valueId)
          AJJ.splice(index, 1)
          that.claimday = AJJ
        }else if(that.dialogVisible3e == 10){
          let index = Akk.findIndex(obj => obj.value  === valueId)
          Akk.splice(index, 1)
          that.leads_customer_service = Akk
        }
      }
    },
    //确定
    determine(){
      var that = this
      if(that.dialogVisible3e == 1){
        that.fieldKey = 'customer_source'
        that.content = that.Customer_worth 
        that.daohang()
      }else if(that.dialogVisible3e == 2){
        that.fieldKey = 'customer_worth'
        that.content = that.leads_worth 
        that.daohang()
      }else if(that.dialogVisible3e == 3){
        that.fieldKey = 'customer_tag'
        that.content = that.leads_customer_tag 
        that.daohang()
      }else if(that.dialogVisible3e == 4){
        that.fieldKey = 'leads_claim_one'
        that.content = that.claimone 
        that.daohang()
      }else if(that.dialogVisible3e == 5){
        that.fieldKey = 'leads_flow_min'
        that.content = that.flowmin 
        that.daohang()
      }else if(that.dialogVisible3e == 6){
        that.fieldKey = 'leads_export_week'
        that.content = that.exportweek 
        that.daohang()
      }else if(that.dialogVisible3e == 7){
        that.fieldKey = 'leads_export_one'
        that.content = that.exportone 
        that.daohang()
      }else if(that.dialogVisible3e == 8){
        that.fieldKey = 'leads_claim_num'
        that.content = that.claimnum 
        that.daohang()
      }else if(that.dialogVisible3e == 9){
        that.fieldKey = 'leads_claim_day'
        that.content = that.claimday 
        that.daohang()
      }else if(that.dialogVisible3e == 10){
        that.fieldKey = 'customer_service'
        that.content = that.leads_customer_service 
        that.daohang()
      }
    },
    //全部线索
    quanbu(){
      var that = this
      axios.get('/admin/customer/config/statisticsColumns',{
        params:{
          type: 'leads'
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.claimday = res.data.data.claim_day
          that.claimone = res.data.data.claim_one
          that.flowmin = res.data.data.flow_min
          that.exportweek = res.data.data.export_week
          that.exportone = res.data.data.export_one
          that.claimnum = res.data.data.claim_num
        }
      })
    },
    //全部确认
    BtnEdit1 () {     
      var that = this
      axios({
        method: 'post',
        url: '/admin/customer/config/edit', 
        data: {
          row:{
            leads:that.ConfigList
          }
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.ruleFormRef = false
          that.loadTable()
        }
      })      
    },
    daohang(){  //添加接口
      var that = this 
      axios({
        method: 'post',
        url: '/admin/company/content/edit',
        data: {
          field_key: that.fieldKey, //内容标识
          content: that.content
        },
      }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.dialogVisible3 = false
            that.staff()
          }
      }) 
    },
    BtnProcess(){   //进程提交
      var that = this
      axios({
          method: 'post',
          url: '/admin/customer/flow/add', 
          data: {
            row:{
              customer_id: that.editId, //线索ID，销售线索时必填
              info: that.Info_desc, //进程的信息
              type: "customer", //类型:leads=线索,customer=客户,order=订单,contract=合同
              source_id: that.editId, //当前类型所在列表ID
              images: that.Row_Eurl2
            }
          },
        }).then(function (res) {
          if(res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.getflow()
            that.Info_desc = ''
            that.fileList1 = []
          }
        })  
    },
    //关闭按钮
    Close() {
      var that = this
      that.ruleFormRef = false;
      that.Row = {}
    },
    staff(){  //列表 
      var that = this
      axios.get('/admin/ajax/staffList',{   // 全部员工列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
            that.StaFFList = res.data.data
        }
      }) 
      axios.get('/admin/company/department/index',{   // 全部部门列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DementList = res.data.data.rows
        }
      })
      axios.get('/admin/company/department/index',{   // 全部部门列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DementList = res.data.data.rows
        }
      }) 
      axios({ method: 'post', url: '/admin/ajax/goodsList', 
      }).then(function (res) {
        if(res.data.code == 0) {
        } else {
          that.leads_demand = res.data.data
          that.leads_demand_value = 0
        }
      })
      axios.get('/admin/customer/customer/statisticsColumns',{
      }).then(function (res) {
        if (res.data.code == 401) {
        } else{
          that.Customer_worth = res.data.data.customer_source  //客户来源
          that.leads_worth = res.data.data.customer_worth     //客户价值
          that.leads_customer_tag = res.data.data.customer_tag   //客户标签
          that.leads_customer_service = res.data.data.customer_service            //服务对象
          that.Customer_worth_value = 0
          that.leads_worth_value = 0
          that.leads_customer_tag_value = 0
        }
      })  
    },
    Chang_ses(e){
      var that = this
      that.type = e
      that.loadTable()
    },
    //导出
    export2Excel() {                                            // 导出
      require.ensure([], () => {
        const { export_json_to_excel } = require('../../../excel/Export2Excel');
        const tHeader = ['姓名', '联系方式', '客户来源', '需求项目' , '客户价值', '创建人', '创建时间'];
        // 上面设置Excel的表格第一行的标题
        const filterVal = ['username', 'mobile', 'source', 'demand', 'worth', 'from.realname', 'createtime'];
        // 上面的index、nickName、name是tableData里对象的属性
        const list = this.tableData;  //把data里的tableData存到list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '客户管理');
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    //导入
    File_Sure(e) {
      var that = this
      axios({
          method: 'post',
          url: '/admin/customer/customer/import', 
          data: {
            file:that.File,
            power:{
              staff_ids:that.File_Yid,
              department_ids: that.File_Bid
            }
          },
        }).then(function (res) {
          if(res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
              that.$message.success(res.data.msg);
              that.editVisible_DEX = false
          }
        })       
    },
    UP_Success3(e) {                //图片上传成功
      var that = this
      that.File = e.data.url
    },
    fileRemove(file, fileList) {                    // 移除选择的文件
      if(fileList.length < 1) {
          this.uploadDisabled = true //未选择文件则禁用上传按钮
      }
    },
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.Row.images = e.data.url
    },
    //图片上传成功
    handleSuccess1(e) {
      var that = this
      that.Row.images = e.data.url
    },
    handleExceed  (files)  {
      this.$refs["upload-images"].clearFiles()
      this.$refs["upload-images"].handleStart(files[0])
      this.$refs["upload-images"].submit();
    },
    handlePreview(file) {
      window.open(file.url);
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
    //勾选
    Selection(e) {
      var that = this
      console.log(e);
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
  }
}
</script>

<style scoped>
.Modification{
  color: #0081FF;
  float: right;
  cursor: pointer;
}
.tttt .EditNewFormItem,
.tttt .EditNewFormBtns{
  margin-bottom: 20px;
}
.EditNewS{
  width: 345px !important;
}
</style>