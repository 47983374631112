<template>
  <div class="SalesManagement_I">
    <div class="headNavs">
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect">
          <el-menu-item index="1">销售线索</el-menu-item>
          <el-menu-item index="2">客户管理</el-menu-item>
          <el-menu-item index="3">订单管理</el-menu-item>
          <el-menu-item index="4">合同管理</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：设置销售线索、客户管理、订单管理、合同管理。</div>
    </div>

    <Customer v-if="isShow == 2" @OffBtn = OffBtn></Customer>   <!-- 客户 -->
    <OrderManagement v-if="isShow == 3" :Retrieve="Retrieve"></OrderManagement>  <!-- 订单 -->
    <Contract v-if="isShow == 4"></Contract>   <!-- 合同 -->
    <div v-if="isShow == 1">
      <div class="capitalNav">
        <div class="capitalLi" style="margin-top:0;"> 
          <div class="capitalLiName">线索来源：</div>
          <span :class="0 == leads_source_value ? 'capitalLiActive': ''" @click="Activ(1, 0)">全部</span>
          <span :class="item.value == leads_source_value ? 'capitalLiActive': ''" @click="Activ(1 , item.value)" 
            v-for="item in leads_source" :key="item.value" :label="item.content" :value="item.value"
          >{{item.content}}</span>
          <span @click="Clue(1)" style="color: #0081FF;">全部线索类型</span>
        </div>
        <div class="capitalLi"> 
          <div class="capitalLiName">线索需求：</div>
          <span :class="0 == leads_demand_value ? 'capitalLiActive': ''" @click="Activ(2, 0)">全部</span>
          <div class="mmmmm">
            <span :class="item.goods_id == leads_demand_value ? 'capitalLiActive': ''" @click="Activ(2 , item.goods_id)" 
              v-for="item in leads_demand" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"
            >{{item.goods_name}}</span>
          </div>
        </div>
        <div class="capitalLi"> 
          <div class="capitalLiName">线索价值：</div>
          <span :class="0 == leads_worth_value ? 'capitalLiActive': ''" @click="Activ(3, 0)">全部</span>
          <span :class="item.value == leads_worth_value ? 'capitalLiActive': ''" @click="Activ(3 , item.value)" 
            v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"
          >{{item.content}}</span>
          <span @click="Clue(2)" style="color: #0081FF;">全部线索类型</span>
        </div>
        <div>
          <div class="NavLi">
            <div class="NavLiName">创建时间：</div>
            <div class="NavLiIpt">
              <el-date-picker v-model="search.time.min" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
              <span class="timeSpan">至</span>
              <el-date-picker v-model="search.time.max" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
            </div>
            <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
            <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
          </div>
        </div>
      </div>

      <div class="TablesList">
        <div class="TablesADD">
          <div class="T_sele dasdas ddsdaasd" style="width:150px">
            <el-select v-model="type" placeholder="请选择线索" @change="Chang_ses">
                <el-option v-for="item in MMD" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="NavLiQuery" @click="showAdd()" v-if="TextMoMo('customer:leads:add')"><i class="el-icon-plus"></i>创建线索</div>
          <div class="NavLiReset" @click="export2Excel()" v-if="TextMoMo('customer:leads:claim')"><i class="el-icon-upload2"></i>导出</div>
          <div class="NavLiReset" @click="editVisible_DEX = true" v-if="TextMoMo('customer:leads:import')"><i class="el-icon-download"></i>导入</div>
          <div class="NavLiReset" @click="batch(4)" v-if="TextMoMo('customer:leads:callback')" v-show ="type == 'self'"><img class="Img_ti" src="../../assets/img/tuihui.png" alt="">批量退回</div>
          <div class="NavLiReset" @click="batch(2)" v-if="TextMoMo('customer:leads:claim')" v-show ="type == 'common'"><i class="el-icon-document"></i>批量认领</div>
          <div class="NavLiReset" @click="batch(3)" v-if="TextMoMo('customer:leads:upgrade')"><img class="Img_ti" src="../../assets/img/shengji.png" alt="">批量升级</div>
          <div class="NavLiReset" @click="batch(1)" v-if="TextMoMo('customer:leads:columns')"><img class="Img_ti" src="../../assets/img/biaoji.png" alt="">批量标记</div>
          <div class="NavLiDelte" @click="batch(5)"><i class="el-icon-delete"></i>批量删除</div>
          <div class="setUp" @click="batch(6)"><i class="el-icon-s-tools"></i></div>
          <div class="TableIpt"> 
            <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入姓名">
            <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
          </div>
        </div>
        <el-table
          :data="tableData"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="Selection">
          <el-table-column type="selection"  width="55"  align="center"></el-table-column>
          <el-table-column prop="id"  label="ID" width="55" align="center"></el-table-column>
          <el-table-column align="center" prop="username" label="姓名"></el-table-column>
          <el-table-column align="center" prop="mobile" label="联系方式"></el-table-column>
          <el-table-column align="center" prop="source" label="线索来源"></el-table-column>
          <el-table-column align="center" prop="demand" label="需求项目">
            <template #default="scope" >
                <span v-for="item in scope.row.goods" :key="item" :label="item" :value="item">{{item}}&nbsp;&nbsp;</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="worth" label="线索价值"></el-table-column>
          <el-table-column align="center" prop="createtime" label="创建时间"></el-table-column>
          <el-table-column align="center" prop="updatetime" label="更新时间"></el-table-column>
          <el-table-column align="center" label="操作" width="200" v-if="type == 'common'">
            <template #default="scope">
              <div class="el_butto">
                <el-button type="text" @click="HandlClaim(scope.row)" v-if="TextMoMo('customer:leads:columns')">认领</el-button>
              </div>
              <div class="el_butto rere" >
                <el-button type="text" class="red" @click="HanDel(scope.row)" v-if="TextMoMo('workbench:worknav:nav:add')" >删除</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="300" v-if="type == 'self'">
            <template #default="scope">
              <div class="el_butto">
                <el-button type="text" @click="HandleEdit(1, scope.row)" v-if="TextMoMo('customer:leads:read')">查看</el-button>
                <el-button type="text" v-if="!TextMoMo('customer:leads:read')">查看</el-button>
              </div>
              <div class="el_butto">
                <el-button type="text" 
                v-if="TextMoMo('customer:leads:edit')" @click="HandleEdit(2, scope.row)">编辑</el-button>
              </div>
              <div class="el_butto rere">
                <el-button
                type="text"
                class="red" v-if="TextMoMo('workbench:worknav:nav:add')" 
                @click="HanDel(scope.row)"  
                >删除</el-button>
              </div>
              <div class="Spliiks" style="width: 32%;float: right">
                <el-dropdown split-button type="primary" @click="handleClick" trigger="click">
                  更多
                  <template #dropdown>  
                    <el-dropdown-menu>
                      <el-dropdown-item v-if="TextMoMo('customer:leads:columns')" >
                        <div class="el_butto dssadd" style="margin:5px 0px 5px 0">  
                          <button @click="Handlsign(scope.row)">标记</button>
                        </div>                          
                      </el-dropdown-item>
                      <el-dropdown-item v-if="TextMoMo('customer:leads:upgrade')" >
                        <div class="el_butto dssadd" style="margin:5px 0px 5px 0">
                          <button @click="HanUpgrade(scope.row)">升级</button>
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item v-if="TextMoMo('customer:leads:callback')" >                  
                        <div class="el_butto dssadd" style="margin:5px 0px 5px 0">
                          <button @click="HandReturn(scope.row)">退回</button>
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 编辑新增 -->
    <div class="show" v-if="ruleFormRef">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <div class="headNavs" style="padding: 0 26px" v-if="isRow == 1">
          <el-menu
            :default-active="acndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2">
            <el-menu-item @click="isShow == 1" index="1">创建线索</el-menu-item>
            <el-menu-item @click="isShow == 2" index="2">查看线索</el-menu-item>
          </el-menu>
        </div>
        <el-form class="EditNewForm" :model="Row" :rules="rules" ref="Row" v-if="isAcndex == 1">
          <el-form-item class="EditNewFormItem" prop="username">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>姓名：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.username" placeholder="请输入姓名" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="mobile">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>联系方式：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.mobile" placeholder="请输入联系方式" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="source_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>需求来源：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.source_id" placeholder="请选择需求来源" :disabled="control">
                <el-option v-for="item in leads_source" :key="item.value" :label="item.content" :value="item.value" ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="worth_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>需求价值：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.worth_id" placeholder="请选择需求价值" :disabled="control">
                <el-option v-for="item in leads_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="goods_ids" >
            <div class="EditNewFormItemName"><span class="signSpan">* </span>需求项目：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.goods_ids" multiple placeholder="请选择需求项目" :disabled="control">
                <el-option v-for="item in leads_demand" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="staff_ids" v-if="type == 'common' ">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>可见员工：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.staff_ids" multiple placeholder="请选择可见员工" :disabled="control">
                <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" v-if="type == 'common' ">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>可见部门：</div>
            <div class="EditNewFormItemIpt">
              <el-cascader
                style="width:100%"
                :options="DementList"
                :props="{ multiple: true, checkStrictly: true, label : 'name', value: 'id'}"
                @change="handleChange2"
                clearable
              ></el-cascader>
              
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">描述（选填）：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="textarea" v-model="Row.description" placeholder="请输入联系方式" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns"  v-if="control ==  true">
            <div class="EditNewFormItemName">图片线索（选填）:</div>
            <div class="EditNewFormItemIpt">
              <img v-for="item in fileList" :key="item" :label="item" :value="item" :src="item" class="Avatar" />
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" v-else>
            <div class="EditNewFormItemName">图片线索（选填）:</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  :action="upload"
                  :headers="heads"
                  list-type="picture-card"
                  :file-list="fileList"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="resetForm == 'xinjian'" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="resetForm == 'baocun'" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>

        <div v-else>
          <div class="EditNewForm">
          <div class="EditNewFormBtns">
            <div class="EditNewFormItemName">全部进程信息</div>
          </div>
          <div class="EditNewFormBtns TRRE" v-if="Flow_list.length != 0">
            <el-scrollbar height="100%">
              <div id="time-line">
                <div class="item" v-for="item in Flow_list" :key="item.index">
                  <div class="ytyt">
                    <div class="item-index">{{ item.createtime }}</div>
                    <div class="item-content">{{ item.info }}</div>
                    <img class="Rtt_im22g" v-for="item in item.imgs_url" :key="item" :label="item" :value="item" :src="item"   />
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="EditNewFormBtns ffff" v-else>
            <div class="EditNewFormItemName">暂无信息</div>
          </div>
          <div class="EditNewFormBtns">
            <div class="EditNewFormItemName">文字记录</div>
            <div class="EditNewFormItemIpt">
              <el-input type="textarea" v-model="Info_desc" placeholder="请将每次沟通、维护、跟单、咨询等相关信息记录在这里，为了提高效率" :disabled="control"></el-input>
            </div>
          </div>
          <div class="EditNewFormBtns gggg">
            <div class="EditNewFormItemName">图片线索（选填）:</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  :action="upload"
                  :headers="heads"
                  list-type="picture-card"
                  :file-list="fileList1"
                  :on-success="handleSuccess1"
                  :on-exceed="handleExceed1"
                  :on-preview="handlePreview1">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </div>
          <div class="EditNewFormBtns">
            <div class="FormBtns">
              <div class="FormBtnsQuery" @click="BtnProcess()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    
    <div class="show" v-if="ruleForm2">
      <div class="rightPanel-background"/>
      <div class="EditNew" style="width:345px">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm" :model="Row" :rules="rules" ref="Row">
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>每日线索认领量（条）
              <span class="Modification" @click="Clue(3)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.claim_day" placeholder="每人每天认领线索上限">
                <el-option v-for="item in claimday" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>单次线索认领量（条）
              <span class="Modification" @click="Clue(4)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.claim_num" placeholder="每人每天认领线索上限">
                <el-option v-for="item in claimone" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>进程更新对低频（天）
              <span class="Modification" @click="Clue(5)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.claim_one" placeholder="低于设置时间将回收销售线索">
                <el-option v-for="item in flowmin" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>每周数据导出频率（次）
              <span class="Modification" @click="Clue(6)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.export_one" placeholder="每周每人可以带出数据次数">
                <el-option v-for="item in exportweek" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>每次数据导出数量（条）
              <span class="Modification" @click="Clue(7)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.export_week" placeholder="每人每次可以导出数量的上限">
                <el-option v-for="item in exportone" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>认领权限设置
              <span class="Modification" @click="Clue(8)">内容管理</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ConfigList.flow_min" placeholder="同一人认领同一线索次数">
                <el-option v-for="item in claimnum" :key="item.value" :label="item.content" :value="item.content"></el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div class="FormBtnsQuery" @click="BtnEdit1()">确定</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 身份升级 -->
    <el-dialog title="身份升级" v-model="editVisible" width="30%">
      <el-form ref="form" label-width="100px">
        <div class="DDGGD">
        <el-form-item label="姓名：">
          <el-input v-model="row.TA_name1" disabled></el-input>
        </el-form-item>
        <el-form-item label="目前身份：">
          <el-input v-model="row.TA_name2" disabled></el-input>
        </el-form-item>
        <el-form-item label="升级到：">
          <div class="rasdas">
            <el-select v-model="row.worthId" placeholder="请选择线索" style="width: 100%;">
              <el-option v-for="item in Customer_worth" :key="item.value" :label="item.content" :value="item.value"></el-option>
            </el-select>
          </div>
        </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer" style="margin-bottom:28px">
          <el-button type="primary" @click="saveEdit()">确 认</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入文件" v-model="editVisible_DEX" width="28%" class="">
        <el-form class="EditNewForm" label-width="120px" style="padding:0">
          <div class="DDGGD">
          <el-form-item class="EditNewFormBtns" label="模板：">
             <el-button type="primary"> <a style="color:#fff" href="./w3school_logo.xlsx" download="w3school_logo.xlsx">下载模板</a></el-button>
          </el-form-item>

          <el-form-item class="EditNewFormBtns" label="选择导入文件：">
            <div class="el-form-item__content">
              <el-upload
                  accept=".xls,.xlsx"
                  :action="upload"
                  :headers="heads"
                  :multiple="false"
                  :file-list="fileList"
                  :on-success="UP_Success3"
                  :on-remove="fileRemove">
                  <el-button size="small" type="primary">选择文件</el-button>
                  <div class="el-upload__tip">一次只能上传一个xls/xlsx文件，且不超过10M</div>
              </el-upload>
          </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" label="可见员工：">
            <div class="EditNewFormItemIpt">
              <el-select v-model="File_Yid" multiple placeholder="请选择可见员工" >
                <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns"  label="可见部门：" >
            <div class="EditNewFormItemIpt">
              <el-cascader :options="DementList" 
              style="width: 100%"
              :props="{multiple: true, label : 'name', value: 'id'}" 
              v-model="department" 
              :show-all-levels="false" 
              placeholder="请选择可见部门"/>
            </div>
          </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" style="margin-right:20px" @click="File_Sure()">确 认</el-button>
          </span>
        </template>
    </el-dialog>
    <!--添加线索来源-->
    <el-dialog :title="titleMath" v-model="dialogVisible3" width="36%">
      <div class="Tabbl">
        <el-table
        :data="ClueData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete(scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="YADD" v-if="isInfo == 'neirong'" @click="CreateAdd(AFF)"><i class="el-icon-plus"></i>创建线索来源</div>
      <div class="YADD" v-if="isInfo == 'jiazhi'" @click="CreateAdd(AFF)"><i class="el-icon-plus"></i>创建线索价值</div>
      <div class="YADD" v-if="isInfo == 'quanbu'" @click="CreateAdd(AFF)"><i class="el-icon-plus"></i>创建全部线索</div>
      <div class="EditNewFormBtns">
        <div class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="determine()">确 定</el-button>
        </div>
      </div>  
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";
import Customer from './components/Customer.vue'
import OrderManagement from './components/OrderManagement.vue'
import Contract from './components/Contract.vue'
export default {
  components:{
    Customer,
    OrderManagement,
    Contract
  },
  data(){
    return{
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      activeIndex:'1',
      multipleSelection_id:'',
      isShow: 1,
      acndex:1,
      File_Yid:'',
      File:"",
      dialogVisible3e:0,
      leads_source_value:0,
      leads_demand_value:0,
      leads_worth_value:0,
      type:'self',
      isRow:'',
      Acndex:'',
      isAcndex:1,
      editId:'',
      dataId:'',
      Info_desc:'',
      titleMath:'',
      resetForm:'',
      total:'',
      Retrieve:'',
      tableData:[],
      Retrieve:[],
      // StaFFList:[],    // 全部员工列表
      // DementList:[],   // 全部部门列表
      Flow_list:[],  // 进程信息
      leads_demand:[],  //线索需求
      leads_worth:[],  //线索价值
      leads_source:[],  //线索来源
      department:[],  //可见部门id
      multipleSelection:[],
      Customer_worth:[],  //升级列表
      fileList:[],
      fileList1:[],
      Row_Eurl2:[],

      // // ------------------- 全部线索-----------------------------
      claimday:[],
      claimone:[],
      flowmin:[],
      exportweek:[],
      exportone:[],
      claimnum:[],
      // //  --------------------------------------------------------
      ruleFormRef:false,
      control:false,

      editVisible:false,
      editVisible_DEX:false,
      ruleForm2:false,
      dialogVisible3:false,
      MMD:[{id:'self',name:'我的线索'},{id:'common',name:'全部线索'}],
      page: {currentPage: 1,pageSize: 10,},// 分页
      search: {  //搜索参数
        source_id:'',
        worth_id:'',
        demand_id:'',
        keyword: "",
        goods_id:'',
        time: {
          min:"",
          max:"",
        },
      },
      Row:{
        type:'',
        username:'',
        mobile:'',
        sex:'',
        source_id:'',
        goods_ids:[],
        worth_id:'',
        description:'',
        images:[],
        staff_ids:[],
        department_ids:[]
      },
      rules:{
        username:[{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobile:[{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        source_id:[{ required: true, message: '请选择需求来源', trigger: 'change' }],
        worth_id:[{ required: true, message: '请选择需求价值', trigger: 'change' }],
        goods_ids:[{ required: true, message: '请选择需求项目', trigger: 'change' }]
      },
      row:{
        worthId:'',
        TA_name1:'',
        TA_name2:'销售线索',
      },
      ConfigList:{
        claim_day:'',
        claim_one:'',
        flow_min:'',
        export_week:'',
        export_one:'',
        claim_num:'',
      }
      
    }
  },
  mounted(){
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    let type = this.$route.query.isShow;
    if(type == 2){
      this.isShow = 2
      this.activeIndex = 2
    }else if(type == 'undefined'){
      this.isShow = 1
      this.activeIndex = 1
    }
    that.loadTable()
    that.staff()
  },
  methods:{
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/customer/leads/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          type: that.type,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    showAdd(){   //打开创建
      var that = this
      that.ruleFormRef = true;
      that.control = false
      that.isAcndex = 1
      that.isRow = ''
      that.titleMath = '创建线索'
      that.resetForm = 'xinjian'
      that.Acndex = ''
      that.staff()
      that.Row = {}
    },
    BtnAdd (){
      var that = this
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'post',
            url: '/admin/customer/leads/add',
            data: {
              row: {
                type: that.type,
                username: that.Row.username,
                mobile: that.Row.mobile,
                sex: that.Row.sex,
                source_id: that.Row.source_id,
                goods_ids: that.Row.goods_ids,
                worth_id: that.Row.worth_id,
                description: that.Row.description,
                images: that.Row.images,
                power:{
                  staff_ids: that.Row.staff_ids,
                  department_ids: that.Row.department_ids,
                }
              },
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef = false
              that.isRow = ''
              that.loadTable();
            }
          }) 
        }
      })
    },
    HandleEdit(e,data) {     // 获取编辑详情                
      var that = this
      if(e == 1){
        that.resetForm = '';
        that.titleMath = '查看线索'
        that.control = true
      }else{
        that.resetForm = 'baocun';
        that.titleMath = '编辑线索'
        that.control = false
        that.isRow = 1
      }
      that.isAcndex = 1
      that.ruleFormRef = true;
      that.editId = data.id
      that.staff()
      that.getflow()
      axios.get('/admin/customer/leads/read', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row.type = res.data.data.row.type
          that.Row.username = res.data.data.row.username
          that.Row.mobile = res.data.data.row.mobile
          that.Row.sex = res.data.data.row.sex
          that.Row.source_id = res.data.data.row.source_id,
          that.Row.worth_id = res.data.data.row.worth_id
          that.Row.description = res.data.data.row.description
          that.Row.images = res.data.data.row.images
          var arr =  res.data.data.row.goods_obj
          var Ner_arr = []
          for (let i = 0; i < arr.length; i++) {
            Ner_arr[i]  =  parseInt(arr[i]);
          }
          that.Row.goods_ids = Ner_arr
          //图片
          let fileArr = [];
          let fileObj = {};
          fileObj.name = "file";
          fileObj.url = res.data.data.row.images;
          fileArr.push(fileObj);
          that.fileList = fileArr
        }
      })
    },
    BtnEdit(){
      var that = this
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'PUT',
            url: '/admin/customer/leads/edit',
            data: {
              id:that.editId,
              row: {
                type: that.type,
                username: that.Row.username,
                mobile: that.Row.mobile,
                sex: that.Row.sex,
                source_id: that.Row.source_id,
                goods_ids: that.Row.goods_ids,
                worth_id: that.Row.worth_id,
                description: that.Row.description,
                images: that.Row.images,
                power:{
                  staff_ids: that.Row.staff_ids,
                  department_ids: that.Row.department_ids,
                }
              },
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef = false
              that.isRow = ''
              that.loadTable();
            }
          }) 
        }
      })
    },
    HanDel(data) {  //删除
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
        method: 'DELETE',
        url: '/admin/customer/leads/del',
        data: {
          ids: arr
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
      })
    },
    BtnProcess(){   //进程提交
      var that = this
      axios({
          method: 'post',
          url: '/admin/customer/flow/add', 
          data: {
            row:{
              leads_id: that.editId, //线索ID，销售线索时必填
              info: that.Info_desc, //进程的信息
              type: "leads", //类型:leads=线索,customer=客户,order=订单,contract=合同
              source_id: that.editId, //当前类型所在列表ID
              images: that.Row_Eurl2
            }
          },
        }).then(function (res) {
          if(res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.getflow()
            that.Info_desc = ''
            that.fileList1 = []
          }
        })  
    },
    Handlsign(data){  //标记
      var that = this 
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要标记吗？", "提示", {
          type: "primary"
      }).then(() => {
        axios({
          method: 'post',
          url: '/admin/customer/leads/contact', 
          data: {
            ids:arr
          },
        }).then(function (res) {
          that.$message.success(res.data.msg);
          that.loadTable()        
        })             
      })   
    },
    batch(e){ //批量
      var that = this 
      var Att = that.multipleSelection
      if( e == 1 ){
        if (that.multipleSelection_id == null) {      //批量标记
            that.$message.warning('请勾选信息');
          } else {
          that.$confirm("确定要批量标记吗？", "提示", {
            type: "primary"
          }).then(() => {
            axios({
              method: 'post',
              url: '/admin/customer/leads/contact', 
              data: {
                ids: that.multipleSelection_id
              },
            }).then(function (res) {
              if(res.data.code == 1){
                that.$message.success(res.data.msg);
                that.loadTable()    
              }else{
                that.$message.warning(res.data.msg);
              }    
            })             
          })
        } 
      }else if( e == 2 ){
        if (that.multipleSelection_id == null) {     //批量认领
            that.$message.warning('请勾选信息');
          } else {
          that.$confirm("确定要批量认领吗？", "提示", {  
              type: "primary"
            }).then(() => {
            axios({
              method: 'POST',
              url: '/admin/customer/leads/claim', 
              data: {
                ids:that.multipleSelection_id
              },
            }).then(function (res) {
              that.$message.success(res.data.msg);
              that.loadTable()        
            })  
          })
        }
      }else if( e == 3 ){ 
        if (that.multipleSelection_id == null) {  //批量升级
            that.$message.warning('请勾选信息');
          } else {
            var auu = []
            that.editVisible = true
            that.staff()
            for (let i = 0; i < Att.length; i++) {
              const element = Att[i].username;
              auu.push(element)
            }
            that.row.TA_name1 = auu.toString()
          }
      }else if( e == 4 ){
        if (that.multipleSelection_id == null) {  //批量退回
          that.$message.warning('请勾选信息');
        } else {
          that.$confirm("确定要批量退回吗？", "提示", {
              type: "primary"
          }).then(() => {
            axios({
              method: 'post',
              url: '/admin/customer/leads/callback', 
              data: {
                ids: that.multipleSelection_id
              },
            }).then(function (res) {
              that.$message.success(res.data.msg);
              that.loadTable()        
            })  
          })
        }
      }else if( e == 5 ){
        if (that.multipleSelection_id == null) {      //批量删除
          that.$message.warning('请勾选信息');
        } else {
          that.$confirm("确定要删除吗？", "提示", {
            type: "primary"
        }).then(() => {
          axios({
            method: 'delete',
            url: '/admin/customer/leads/del',
            data: {
              ids: that.multipleSelection_id
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.loadTable()
              }
            }) 
          })
        }
      }else if( e == 6 ){
        that.ruleForm2 = true
        that.titleMath = '全部线索管理'
        that.quanbu()
        axios.get('/admin/customer/config/index',{   // 全部部门列表
            params:{
              type: 'leads'
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
            that.ConfigList = res.data.data.config
          }
        })
      }
    },
    OffBtn(id){
      this.activeIndex = 3
      this.isShow = 3
      this.Retrieve.push(id)
    },
    HanUpgrade(data){   //打开升级
      var that = this 
      that.editVisible = true
      that.staff()
      that.row.TA_name1 = data.username
      that.row.TA_name2 = '销售线索'
      that.dataId = data.id
    },
    saveEdit(){   //确认升级
      var that = this 
      var arr = []
      arr.push(that.dataId)
      axios({
        method: 'post',
        url: '/admin/customer/leads/upgrade', 
        data: {
          ids:arr,
          worth_id:that.row.worthId
        },
      }).then(function (res) {
        that.$message.success(res.data.msg);
        that.editVisible = false
        that.loadTable()
      })
    },
    HandReturn(data){  //退回
      var that = this 
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要退回吗？", "提示", {
          type: "primary"
      }).then(() => {
        axios({
          method: 'post',
          url: '/admin/customer/leads/callback', 
          data: {
            ids: arr
          },
        }).then(function (res) {
          that.$message.success(res.data.msg);
          that.loadTable()        
        })             
      })  
    },
    export2Excel() {  //导出
      require.ensure([], () => {
        const { export_json_to_excel } = require('../../excel/Export2Excel');
        const tHeader = ['姓名', '联系方式', '线索来源', '需求项目' , '线索价值', '创建人', '创建时间', '地区'];
        // 上面设置Excel的表格第一行的标题
        const filterVal = ['username', 'mobile', 'source', 'demand', 'worth', 'realname', 'createtime', 'is_contact_text'];
        // 上面的index、nickName、name是tableData里对象的属性
        const list = this.tableData;  //把data里的tableData存到list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '销售线索');
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    File_Sure(e) { //导入
      var that = this
      axios({
          method: 'post',
          url: '/admin/customer/leads/import', 
          data: {
            file:that.File,
            power:{
              staff_ids:that.File_Yid,
              department_ids: that.department
            }
          },
        }).then(function (res) {
          if(res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
              that.$message.success(res.data.msg);
              that.editVisible_DEX = false
          }
        })       
    },
    HandlClaim(data){ //认领
      var that = this 
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要认领吗？", "提示", {  
          type: "primary"
        }).then(() => {
        axios({
          method: 'POST',
          url: '/admin/customer/leads/claim', 
          data: {
            ids:arr
          },
        }).then(function (res) {
          that.$message.success(res.data.msg);
          that.loadTable()        
        })  
      }) 
    },
    BtnSearch(e) {   //搜索
      var that = this
      let time = ''
      let time2 = ''
      that.page.currentPage = 1;
      if( e == false){
        that.search = {
          source_id:'',
          worth_id:'',
          demand_id:'',
          keyword: "",
          goods_id:'',
          time: {
            min:"",
            max:"",
          },
        }
      }
      if (that.search.time.min != '' ) {
        time = that.formatDate(that.search.time.min)
      }
      if (that.search.time.max != '' ) {
        time2 = that.formatDate(that.search.time.max)
      }
      axios({
        method: 'post',
        url: '/admin/customer/leads/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          type: that.type,
          filter: {
            source_id: that.leads_source_value,
            worth_id: that.leads_worth_value,
            demand_id: that.leads_demand_value,
            keyword: that.search.keyword,
            goods_id: that.leads_demand_value,
            time: {
              min: time,
              max: time2,
            },
          },
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    Activ(e, id) {   //列表搜索
      var that = this
      if (e == 1) {
        that.leads_source_value = id
        that.BtnSearch()
      } else if (e == 2) {
        that.leads_demand_value = id
        that.BtnSearch()
      } else if (e == 3) {
        that.leads_worth_value = id
        that.BtnSearch()
      } 
    },
    Clue(e){ //添加线索
      this.dialogVisible3 = true
      this.dialogVisible3e = e
      if( e == 1 ){
        this.ClueData = this.leads_source
        this.titleMath = '添加线索来源'
        this.isInfo = 'neirong'
        this.AFF = 1
      }else if( e == 2 ){
        this.ClueData = this.leads_worth
        this.titleMath = '添加线索价值'
        this.isInfo = 'jiazhi'
        this.AFF = 2
      }else if( e == 3 ){
        this.ClueData = this.claimday
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 3
      }else if( e == 4 ){
        this.ClueData = this.claimone
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 4
      }else if( e == 5 ){
        this.ClueData = this.flowmin
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 5
      }else if( e == 6 ){
        this.ClueData = this.exportweek
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 6
      }else if( e == 7 ){
        this.ClueData = this.exportone
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 7
      }else if( e == 8 ){
        this.ClueData = this.claimnum
        this.titleMath = '全部线索管理'
        this.isInfo = 'quanbu'
        this.AFF = 8
      }
    },
    CreateAdd(e){
      var that = this
      var srt = {
        content: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      var srt2 = {
        content: "10",
        status: 'normal',
        type: "custom",
        value:0,
      }
      console.log(e);
      if( e == 1 ){
        var ATT = that.leads_source
        ATT.push(srt)
        that.leads_source = ATT
      }else if( e == 2 ){
        var ADD = that.leads_worth
        ADD.push(srt)
        that.leads_worth = ADD
      }else if( e == 3){
        var ABB = that.claimday
        ABB.push(srt2)
        that.claimday = ABB
      }else if( e == 4){
        var ACC = that.claimone
        ACC.push(srt2)
        that.claimone = ACC
      }else if( e == 5){
        var AEE = that.flowmin
        AEE.push(srt2)
        that.flowmin = AEE
      }else if( e == 6){
        var AFF = that.exportweek
        AFF.push(srt2)
        that.exportweek = AFF
      }else if( e == 7){
        var AGG = that.exportone
        AGG.push(srt2)
        that.exportone = AGG
      }else if( e == 8){
        var AHH = that.claimnum
        AHH.push(srt2)
        that.claimnum = AHH
      }
    },
    //确定
    determine(){
      var that = this
      if(that.dialogVisible3e == 1){
        that.fieldKey = 'leads_source'
        that.content = that.leads_source 
        that.daohang()
      }else if(that.dialogVisible3e == 2){
        that.fieldKey = 'leads_worth'
        that.content = that.leads_worth 
        that.daohang()
      }else if(that.dialogVisible3e == 3){
        that.fieldKey = 'leads_claim_day'
        that.content = that.claimday 
        that.daohang()
      }else if(that.dialogVisible3e == 4){
        that.fieldKey = 'leads_claim_one'
        that.content = that.claimday 
        that.daohang()
      }else if(that.dialogVisible3e == 5){
        that.fieldKey = 'leads_flow_min'
        that.content = that.claimday 
        that.daohang()
      }else if(that.dialogVisible3e == 6){
        that.fieldKey = 'leads_export_week'
        that.content = that.claimday 
        that.daohang()
      }else if(that.dialogVisible3e == 7){
        that.fieldKey = 'leads_export_one'
        that.content = that.claimday 
        that.daohang()
      }else if(that.dialogVisible3e == 8){
        that.fieldKey = 'leads_claim_num'
        that.content = that.claimday 
        that.daohang()
      }
    },
    //导航删除
    haeDete(data){
      var that = this 
      console.log(data);
      console.log(that.dialogVisible3e);
      let valueId = data.value
      var ATT = that.leads_source
      var ADD = that.leads_worth
      var ABB = that.claimday
      var ACC = that.claimone
      var AEE = that.flowmin
      var AFF = that.exportweek
      var AGG = that.exportone
      var AHH = that.claimnum
      if (data.type == 'default') {
      } else {
        if(that.dialogVisible3e == 1){
          let index = ATT.findIndex(obj => obj.value  === valueId)
          ATT.splice(index, 1)
          that.leads_source = ATT
        }else if(that.dialogVisible3e == 2){
          let index = ADD.findIndex(obj => obj.value  === valueId)
          ADD.splice(index, 1)
          that.leads_worth = ADD
        }else if(that.dialogVisible3e == 3){
          let index = ABB.findIndex(obj => obj.value  === valueId)
          ABB.splice(index, 1)
          that.claimday = ABB
        }else if(that.dialogVisible3e == 4){
          let index = ACC.findIndex(obj => obj.value  === valueId)
          ACC.splice(index, 1)
          that.claimday = ACC
        }else if(that.dialogVisible3e == 5){
          let index = AEE.findIndex(obj => obj.value  === valueId)
          AEE.splice(index, 1)
          that.claimday = AEE
        }else if(that.dialogVisible3e == 6){
          let index = AFF.findIndex(obj => obj.value  === valueId)
          AFF.splice(index, 1)
          that.claimday = AFF
        }else if(that.dialogVisible3e == 7){
          let index = AGG.findIndex(obj => obj.value  === valueId)
          AGG.splice(index, 1)
          that.claimday = AGG
        }else if(that.dialogVisible3e == 8){
          let index = AHH.findIndex(obj => obj.value  === valueId)
          AHH.splice(index, 1)
          that.claimday = AHH
        }
      }
    },
    daohang(){  //添加接口
      var that = this 
      axios({
        method: 'post',
        url: '/admin/company/content/edit',
        data: {
          field_key: that.fieldKey, //内容标识
          content: that.content
        },
      }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.dialogVisible3 = false
            that.staff()
          }
      }) 
    },
    staff(){  //列表 
      var that = this
      axios.get('/admin/ajax/staffList',{   // 全部员工列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
            that.StaFFList = res.data.data
        }
      }) 
      axios.get('/admin/company/department/index',{   // 全部部门列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DementList = res.data.data.rows
        }
      }) 
      axios({ method: 'post', url: '/admin/ajax/goodsList', 
      }).then(function (res) {
        if(res.data.code == 0) {
        } else {
          that.leads_demand = res.data.data
          that.leads_demand_value = 0
        }
      })
       axios.get('/admin/customer/leads/statisticsColumns',{ 
      }).then(function (res) {
        if (res.data.code == 401) {;
        } else{
          that.leads_worth = res.data.data.leads_worth
          that.leads_source = res.data.data.leads_source
          that.leads_source_value = 0
          that.leads_worth_value = 0
        }
      }) 
      axios.get('/admin/customer/customer/statisticsColumns',{
      }).then(function (res) {
        if (res.data.code == 401) {
        } else{
          that.Customer_worth = res.data.data.customer_worth
        }
      })  
      
    },
    getflow(){
      var that = this
      axios.get('/admin/customer/flow/read',{   // 线索 进程信息
          params:{
            leads_id: that.editId
          }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else { 
          that.Flow_list = res.data.data.flow_list
        }
      }) 
    },
    quanbu(){ //全部线索
      var that = this
      axios.get('/admin/customer/config/statisticsColumns',{
        params:{
          type: 'leads'
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.claimday = res.data.data.claim_day
          that.claimone = res.data.data.claim_one
          that.flowmin = res.data.data.flow_min
          that.exportweek = res.data.data.export_week
          that.exportone = res.data.data.export_one
          that.claimnum = res.data.data.claim_num
        }
      })
    },
    BtnEdit1 () {   //全部确认 
      var that = this
      axios({
        method: 'post',
        url: '/admin/customer/config/edit', 
        data: {
          row:{
            leads:that.ConfigList
          }
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.ruleFormRef = false
          that.loadTable()
        }
      })      
    },
    handleSelect(key){ //导航选择
      if(key == 1){
        this.isShow = 1
      }else if(key == 2){
        this.isShow = 2
      }else if(key == 3){
        this.isShow = 3
      }else if(key == 4){
        this.isShow = 4
      }
    },
    handleSelect2(key){
      if(key == 1){
        this.isAcndex = 1
      }else if(key == 2){
        this.isAcndex = 2
      }
    },
    Close() {  //关闭按钮
      var that = this
      that.ruleFormRef = false;
      that.ruleForm2 = false
      that.isRow = ''
      that.Row = {}
    },
    Selection(e) { //勾选
      var that = this
      console.log(e);
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    Chang_ses(e){
      var that = this
      that.type = e
      that.loadTable()
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    handleChange2(e) {
      var that = this
      var att = []
      for (let i = 0; i < e.length; i++) {
        const element = e[i];
        att.push(element[1])
      }
      that.department = att
    },
    UP_Success3(e) {                //图片上传成功
      var that = this
      that.File = e.data.url
    },
    // //图片上传成功
    handleSuccess(e) {
      var that = this
      that.Row.images = e.data.url
    },
    handleSuccess1(e) {
      var that = this
      that.Row_Eurl2 = e.data.url
    },
    handleExceed  (files)  {
      this.$refs["upload-images"].clearFiles()
      this.$refs["upload-images"].handleStart(files[0])
      this.$refs["upload-images"].submit();
    },
    handlePreview(file) {
      window.open(file.url);
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    }
  }
}
</script>
<style scoped>
.rere{
  margin-right: 0 !important;
}

.EditNewFormItemIpt .el-cascader .el-input__inner{
  height: 32px !important;
  line-height: 40px !important;
}
.Modification{
  color: #0081FF;
  float: right;
  cursor: pointer;
  margin-right: -13px;
}
.mmmmm{
  width: 85%;
  overflow-x: auto;
}
.TableIpt{
  width: 13%;
}
.TableIpt input{
  width: 100%;
}

</style>