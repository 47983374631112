<template>
  <div class="OrderManagement_i">
    <div class="capitalNav">
      <div class="capitalLi" style="margin-top:0;"> 
        <div class="capitalLiName">订单来源：</div>
        <span @click="Activ(1, 0)" :class="0 == PayType_value ? 'capitalLiActive' : ''">全部</span>
        <span @click="Activ(1 , i)" :class="i == PayType_value ? 'capitalLiActive' : ''" 
            v-for="(item, i) in statisticsColumns.pay_type" :key="i" :label="item" :value="i"
        >{{item}}</span>
      </div>
      <div class="capitalLi" style="margin-top:0;"> 
        <div class="capitalLiName">销售项目：</div>
        <div>
          <span @click="Activ(2, 0)" :class="0 == goodsList_value ? 'capitalLiActive' : ''">全部</span>
          <span @click="Activ(2 , item.goods_id)" :class="item.goods_id == goodsList_value ? 'capitalLiActive' : ''" 
            v-for="item in goodsList" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"
          >{{item.goods_name}}</span>
        </div>
      </div>
      <div class="capitalLi" style="margin-top:0;"> 
        <div class="capitalLiName">支付方式：</div>
        <span @click="Activ(3, 0)" :class="0 == Paymethod_value ? 'capitalLiActive' : ''">全部</span>
        <span @click="Activ(3 , i)" :class="i == Paymethod_value ? 'capitalLiActive' : ''" 
            v-for="(item, i) in statisticsColumns.pay_method" :key="i" :label="item" :value="i"
        >{{item}}</span>
      </div>

      <div style="display:flex;">
        <div class="NavLi" style="margin-right:30px">
          <div class="NavLiName">订单金额：</div>
          <div class="NavLiIpt" style="display:flex;">
            <el-input type="number" v-model="search.money.min" placeholder="输入具体数值"></el-input>
            <span class="timeSpan" style="margin-top:8px">至</span>
            <el-input type="number" v-model="search.money.max" placeholder="输入具体数值"></el-input>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">创建时间：</div>
          <div class="NavLiIpt">
            <el-date-picker v-model="search.time.min" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
            <span class="timeSpan">至</span>
            <el-date-picker v-model="search.time.max" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
          </div>
        </div>
        
      </div>
      <div style="display:flex;margin-top:20px;">
        <div class="NavLi" style="margin-right:30px">
          <div class="NavLiName">筛选：</div>
          <div class="NavLiIpt" style="display:flex;">
            <el-select v-model="search.business_id" placeholder="服务商">
              <el-option v-for="item in BusinessList" :key="item.id" :label="item.username" :value="item.id"></el-option>
            </el-select>
            <el-select v-model="search.site_id" placeholder="站点">
              <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"> </el-option>
            </el-select>
            <el-select v-model="search.pay_status" placeholder="支付状态">
              <el-option v-for="(item,i) in statisticsColumns.pay_status" :key="i" :label="item" :value="i"></el-option>
            </el-select>
            <el-select v-model="search.is_confirm" placeholder="接单状态">
              <el-option v-for="(item,i) in statisticsColumns.is_confirm" :key="i" :label="item" :value="i"></el-option>
            </el-select>
            <el-select v-model="search.back_status" placeholder="退款">
              <el-option v-for="(item,i) in statisticsColumns.back_status" :key="i" :label="item" :value="i"></el-option>
            </el-select>
            <el-select v-model="search.pay_form" placeholder="付款形式">
              <el-option v-for="(item,i) in statisticsColumns.pay_form" :key="i" :label="item" :value="i"></el-option>
            </el-select>
          </div>
          <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
        </div>
      </div>
    </div>


    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建订单</div>
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-download"></i>导出</div>
        <div class="NavLiDelte" @click="Add_Del(false)"><i class="el-icon-delete"></i>批量删除</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入订单号">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="Selection">
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
        <el-table-column align="center" prop="customer.username" label="买家姓名"></el-table-column>
        <el-table-column align="center" prop="customer.mobile" label="联系方式"></el-table-column>
        <el-table-column align="center" prop="order_no" label="订单号" width="200"></el-table-column>
        <el-table-column align="center" prop="goodsone.goods_name" label="购买项目" width="180"></el-table-column>
        <el-table-column align="center" prop="pay_price" label="支付金额"></el-table-column>
        <el-table-column align="center" prop="unpaid.unpaid_money" label="剩余金额"></el-table-column>
        <el-table-column align="center" prop="pay_method_text" label="支付方式"></el-table-column>
        <el-table-column align="center" prop="is_confirm_text" label="接单状态"></el-table-column>
        <el-table-column align="center" prop="back_status_text" label="退款列表"></el-table-column>
        <el-table-column align="center" label="操作" width="300">
          <template #default="scope">
            <div class="el_butto">
              <el-button type="text" 
              v-if="TextMoMo('order:order:confirm')" @click="HandleRece(1,scope.row)">接单</el-button>
            </div>
            <div class="el_butto">
              <el-button type="text" 
              v-if="TextMoMo('order:order:edit')" @click="HandleEdit(scope.row)">编辑</el-button>
            </div>
            <div class="el_butto rere">
              <el-button
              type="text"
              class="red" v-if="TextMoMo('order:order:del')"
              @click="HanDel(scope.row)"  
              >删除</el-button>
            </div>
            <div class="Spliiks" style="width: 32%;float: right">
              <el-dropdown split-button type="primary" @click="handleClick" trigger="click">
                更多
                <template #dropdown>  
                  <el-dropdown-menu>
                    <el-dropdown-item v-if="TextMoMo('order:order:transfer')">
                      <div class="el_butto dssadd" style="margin:5px 0px 5px 0">  
                        <button @click="HandleRece(2,scope.row)">转移</button>
                      </div>                          
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div class="el_butto dssadd" style="margin:5px 0px 5px 0">
                        <button @click="HanUpgrade(scope.row)">升级</button>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item>                  
                      <div class="el_butto dssadd" style="margin:5px 0px 5px 0">
                        <button @click="Handlupgrade(scope.row)">升级</button>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>


    <!-- 编辑新增 -->
    <div class="show" v-if="ruleFormRef">
      <div class="rightPanel-background"/>
      <div class="EditNew" style="width:822px">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <div class="headNavs" style="padding: 0 26px" v-if="isRow == 1">
          <el-menu
            :default-active="acndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2">
            <el-menu-item @click="isShow == 1" index="1">订单管理</el-menu-item>
            <el-menu-item @click="isShow == 2" index="2">查看线索</el-menu-item>
          </el-menu>
        </div>
        <el-form class="EditNewForm" :model="ruleForm" :rules="rules" ref="ruleForm" v-if="isAcndex == 1">
          <el-form-item class="EditNewFormBtns gg">
            <div class="EditNewFormItemName">买家信息</div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.username">
            <div class="EditNewFormItemName" style="margin-bottom:8px"><span class="signSpan">* </span>买家姓名（匹配确认）：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ruleForm.Row.username" placeholder="请选择买家姓名"  @change="Chanssa">
                <el-option v-for="item in customerList" :key="item.id" :label="item.username" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>买家联系方式（匹配确认）：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="ruleForm.Row.mobile" placeholder="请输入联系方式" disabled class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>买家来源（匹配确认）：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="valueB" placeholder="请输入买家来源" disabled class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns gg">
            <div class="EditNewFormItemName">服务对象信息</div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" v-for="(item, index) in ruleForm.person" :key="index">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>服务对象({{index + 1}}):</div>
            <div style="display:flex">
              <div class="EditNewFormItemIpt AAA">
                <el-input v-model="item.username" placeholder="服务对象名称（必填）"></el-input>
              </div>
              <div class="EditNewFormItemIpt AAA">
                <el-input v-model="item.mobile" placeholder="手机号（必填）"></el-input>
              </div>
              <div class="EditNewFormItemIpt AAA">
                <el-input v-model="item.company" placeholder="工作单位（全称）"></el-input>
              </div>
              <div class="EditNewFormItemIpt AAA">
                <el-input v-model="item.id_card" placeholder="有效证件号"></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName" @click="duixiang()" style="color: #1890FF;cursor: pointer;">服务对象信息</div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns gg">
            <div class="EditNewFormItemName">订单信息</div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.order_no">
            <div class="EditNewFormItemName" style="margin-bottom:1px"><span class="signSpan">* </span>订单号（系统自动生成）：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="ruleForm.Row.order_no" disabled class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.business_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>服务商（员工）：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ruleForm.Row.business_id" placeholder="请选择销售人员是谁">
                <el-option v-for="item in BusinessList" :key="item.id" :label="item.username" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.site_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>站点：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ruleForm.Row.site_id" placeholder="请选择订单属于哪个站点">
                <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.goods_price">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品单价：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleForm.Row.goods_price" placeholder="请填写商品单价"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.goods_num">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品数量：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleForm.Row.goods_num" placeholder="请填写商品数量"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.total_price">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品应收总金额：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleForm.Row.total_price" placeholder="请输入订单总价"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.goods_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>需求项目：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ruleForm.Row.goods_id" placeholder="请选择需求项目">
                <el-option v-for="item in goodsList" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns gg">
            <div class="EditNewFormItemName">付款对象</div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.discount">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>优惠金额：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleForm.Row.discount" placeholder="请填写优惠金额"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.pay_price">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>实际支付金额：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleForm.Row.pay_price" placeholder="请填写实际支付金额"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.unpaid_money">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>剩余金额：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleForm.Row.unpaid_money" placeholder="请填写剩余金额"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.pay_time">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>付款时间：</div>
            <div class="EditNewFormItemIpt">
              <el-date-picker v-model="ruleForm.Row.pay_time" type="date" placeholder="选择月份"></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.pay_type">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>支付方式：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ruleForm.Row.pay_type" placeholder="请选择支付方式">
                <el-option v-for="(item , i) in statisticsColumns.pay_method" :key="i" :label="item" :value="i"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.pay_form">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>付款形式：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ruleForm.Row.pay_form" placeholder="请选择付款形式">
                <el-option v-for="(item , i) in statisticsColumns.pay_form" :key="i" :label="item" :value="i"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns gg">
            <div class="EditNewFormItemName">尾款信息</div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="unpaid.expect_pay_time">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>尾款应付时间：</div>
            <div class="EditNewFormItemIpt">
              <el-date-picker v-model="ruleForm.unpaid.expect_pay_time" type="date" placeholder="选择尾款应付时间"></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="unpaid.pay_time">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>尾款付款时间：</div>
            <div class="EditNewFormItemIpt">
              <el-date-picker v-model="ruleForm.unpaid.pay_time" type="date" placeholder="选择尾款付款时间"></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="unpaid.pay_type">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>尾款支付方式：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ruleForm.unpaid.pay_type" placeholder="请选择支付方式">
                <el-option v-for="(item , i) in statisticsColumns.pay_method" :key="i" :label="item" :value="i"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="unpaid.payable_money">
            <div class="EditNewFormItemName"><span class="signSpan"> </span>尾款应付金额：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleForm.unpaid.payable_money" placeholder="请填写应付金额"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="unpaid.pay_money">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>尾款实际支付金额：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleForm.unpaid.pay_money" placeholder="请填写实际支付金额"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns gg">
            <div class="EditNewFormItemName">附件</div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="Row.remark">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>订单备注（选填）：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="textarea" v-model="ruleForm.Row.remark" placeholder="备注信息可以写在这里，方便日后更好的查询与记忆"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">合同/付款凭证/重要信息等上传:</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  :action="upload"
                  :headers="heads"
                  list-type="picture-card"
                  :file-list="fileList"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="resetForm == 'xinjian'" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="resetForm == 'baocun'" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
        <div v-else>
          <div class="EditNewForm">
            <div class="EditNewFormBtns">
              <div class="EditNewFormItemName">全部进程信息</div>
            </div>
            <div class="EditNewFormBtns TRRE" v-if="Flow_list.length != 0">
              <el-scrollbar height="100%">
                <div id="time-line">
                  <div class="item" v-for="item in Flow_list" :key="item.index">
                    <div class="ytyt">
                      <div class="item-index">{{ item.createtime }}</div>
                      <div class="item-content">{{ item.info }}</div>
                      <img class="Rtt_im22g" v-for="item in item.imgs_url" :key="item" :label="item" :value="item" :src="item"   />
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
            <div class="EditNewFormBtns ffff" v-else>
              <div class="EditNewFormItemName">暂无信息</div>
            </div>
            <div class="EditNewFormBtns">
              <div class="EditNewFormItemName">文字记录</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="Info_desc" placeholder="请将每次沟通、维护、跟单、咨询等相关信息记录在这里，为了提高效率" :disabled="control"></el-input>
              </div>
            </div>
            <div class="EditNewFormBtns gggg">
              <div class="EditNewFormItemName">图片线索（选填）:</div>
              <div class="EditNewFormItemIpt">
                <div class="Picture">
                  <el-upload
                    :action="upload"
                    :headers="heads"
                    list-type="picture-card"
                    :file-list="fileList1"
                    :on-success="handleSuccess1"
                    :on-exceed="handleExceed1"
                    :on-preview="handlePreview1">
                    <i class="el-icon-plus"></i>
                    <div class="elsff">上传图片</div>
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="EditNewFormBtns">
              <div class="FormBtns">
                <div class="FormBtnsQuery" @click="BtnProcess()">保存</div>
                <div class="FormBtnsClose" @click="Close()">取消</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 接单 -->
    <el-dialog title="接单" v-model="editVisible2" width="26%">
        <el-form ref="form" label-width="100px" v-if="editVis == 1">
          <el-form-item label="服务周期：">
            <el-input v-model="Row1.time" placeholder="填写服务周期，实例30"></el-input>
          </el-form-item>
          <el-form-item label="周期类型：">
            <el-radio style="margin-top:14px" v-model="Row1.type" label="天">天</el-radio>
            <el-radio style="margin-top:14px" v-model="Row1.type" label="月">月</el-radio>
          </el-form-item>
          <el-form-item label="用户姓名：">
            <el-input v-model="Row1.realname" placeholder="填写用户姓名"></el-input>
          </el-form-item>
        </el-form>
        <el-form ref="form" label-width="100px" v-if="editVis == 2">
          <el-form-item label="订单编号：">
            <el-input v-model="time1" placeholder="填写订单编号" disabled></el-input>
          </el-form-item>
          <el-form-item label="商品名称：">
            <el-input v-model="time2" placeholder="填写商品名称" disabled></el-input>
          </el-form-item>
          <el-form-item label="服务商：">
            <el-select v-model="staff_id" placeholder="请选择服务商">
              <el-option v-for="item in BusinessList" :key="item.id" :label="item.username" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button v-if="editVis == 1" type="primary" @click="saveEdit2()">确 认</el-button>
            <el-button v-if="editVis == 2" type="primary" @click="saveEdit()">确 认</el-button>
          </span>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import TextMoth from "../../../components/utils.vue";
import axios from 'axios'
export default {
  props:{
    Retrieve: Array,
  },
  data(){
    return{
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      titleMath:'',
      resetForm:'',
      editId:'',
      editVis:'',
      total:'',
      isRow:1,
      isAcndex:1,
      acndex:1,
      PayType_value:0,
      goodsList_value:0,
      Paymethod_value:0,
      valueB:'',
      Info_desc:'',
      staff_id:'',
      time1:'',
      time2:'',
      pay_form:'',
      RetrieveId:'',
      page: {currentPage: 1,pageSize: 10},// 分页
      search: {  //搜索参数
        business_id:'',
        site_id:'',
        is_confirm:'',
        pay_status:'',
        back_status:'',
        pay_form:'',
        pay_type:'',
        goods_id:'',
        keyword:'',
        time: {
          min:"",
          max:"",
        },
        money: {
          min:"",
          max:"",
        },
      },
      ruleForm:{
        Row:{
          customer_id:"",
          username:"",
          mobile:"",
          source_id:"",
          goods_id:"",
          business_id:'',
          site_id:'',
          goods_price:"",
          goods_num:"",
          total_price:"",
          order_no:"",
          discount:"",
          pay_price:"",
          unpaid_money:"",
          pay_time:"",
          pay_type:"",
          pay_form:"",
          remark:"",
          images:[],
        },
        person:[{mobile:'',username:'',company:'',id_card:''}],  //服务对象
        unpaid:{expect_pay_time:"",pay_time:"",pay_type:'',payable_money:"",pay_money:""}        
      },
      rules:{
        Row:{
          username: [{ required: true, message: '请填写', trigger: 'change' }],
          order_no: [{ required: true, message: '请填写', trigger: 'blur' }],
          business_id: [{ required: true, message: '请选择', trigger: 'change' }],
          site_id: [{ required: true, message: '请选择', trigger: 'change' }],
          goods_id: [{ required: true, message: '请选择', trigger: 'change' }],
          pay_type: [{ required: true, message: '请选择', trigger: 'change' }],
          pay_form: [{ required: true, message: '请选择', trigger: 'change' }],
          goods_price: [{ required: true, message: '请填写', trigger: 'blur' }],
          goods_num: [{ required: true, message: '请填写', trigger: 'blur' }],
          total_price: [{ required: true, message: '请填写', trigger: 'blur' }],
          discount: [{ required: true, message: '请填写', trigger: 'blur' }],
          pay_price: [{ required: true, message: '请填写', trigger: 'blur' }],
          unpaid_money: [{ required: true, message: '请填写', trigger: 'blur' }],
          pay_time: [{ required: true, message: '请填写', trigger: 'blur' }],
          remark: [{ required: true, message: '请填写', trigger: 'blur' }]
        },
        unpaid:{
          expect_pay_time: [{ required: true, message: '请选择', trigger: 'change' }],
          pay_time: [{ required: true, message: '请选择', trigger: 'change' }],
          pay_type: [{ required: true, message: '请选择', trigger: 'change' }],
          payable_money: [{ required: true, message: '请输入', trigger: 'blur' }],
          pay_money: [{ required: true, message: '请输入', trigger: 'blur' }]
        }
      },
      Row1:{
        time:'',
        type:'天',
        realname:'',
      },
      statisticsColumns:[],
      goodsList:[],
      tableData:[],
      customerList:[],
      BusinessList:[],
      SiteList:[],
      fileList:[],
      Flow_list:[],
      fileList1:[],
      Row_Eurl2:[],
      multipleSelection:[],
      multipleSelection_id:[],
      ruleFormRef:false,
      editVisible2:false,
    }
  },
  mounted(){
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()
    that.staff()
    that.RetrieveId = this.Retrieve[0]
    if(that.RetrieveId != undefined){
      that.showAdd()
      that.ruleForm.Row.username = that.RetrieveId
      axios({                   
        method: 'post',
        url: '/admin/ajax/customerList',    
      }).then(function (res) { 
        that.customerList = res.data.data
        that.Chanssa(that.RetrieveId)
      }) 
    }
  },
  methods:{
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/order/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: {
            business_id : that.search.business_id,
            site_id : that.search.site_id,
            is_confirm : that.search.is_confirm,
            pay_status : that.search.pay_status,
            back_status : that.search.back_status,
            pay_form : that.search.pay_form || that.pay_form,
            pay_type : that.search.pay_type,
            goods_id : that.search.goods_id,
            keyword : that.search.keyword,
            time: {
              min : that.search.time.min,
              max : that.search.time.max,
            },
            money: {
              min : that.search.money.min,
              max : that.search.money.max,
            },
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    BtnSearch(e){
      if(e == false){
        this.search = {
          business_id:'',
          site_id:'',
          is_confirm:'',
          pay_status:'',
          back_status:'',
          pay_form:'',
          pay_type:'',
          goods_id:'',
          keyword:'',
          time: {
            min:"",
            max:"",
          },
          money: {
            min:"",
            max:"",
          },
        }
      }
      this.loadTable()
    },
    showAdd(){
      var that = this
      axios.get('/admin/order/order/info',{
      }).then(function (res) {
        that.ruleForm.Row.order_no = res.data.data.order_no
      }) 
      that.ruleFormRef = true
      that.titleMath = '创建订单'
      that.resetForm = 'xinjian'
      that.isRow = ''
    },
    BtnAdd(){
      var that = this
      that.$refs['ruleForm'].validate((valid) =>{
        if (valid) {
          if(that.ruleForm.person[0].username == ''){
            that.$message.warning('请填写服务对象名称');
            return
          }
          if(that.ruleForm.person[0].mobile == ''){
            that.$message.warning('请填写服务对象手机号');
            return
          }
          if(that.ruleForm.person[0].company == ''){
            that.$message.warning('请填写工作单位');
            return
          }
          if(that.ruleForm.person[0].id_card == ''){
            that.$message.warning('请填写有效证件号');
            return
          }
          axios({
            method: 'post',
            url: '/admin/order/order/add',
            data: {
              row: that.ruleForm.Row,
              person: that.ruleForm.person,
              unpaid: that.ruleForm.unpaid,
            },
          }).then(function (res) {
            console.log(res.data.data);
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef = false
              that.loadTable();
            }
          })
        }
      })
    },
    showflow(){
      var that = this
      axios.get('/admin/order/order/showflow',{   // 线索 进程信息
        params:{
          id: that.editId
        }
      }).then(function (res) {
        that.Flow_list = res.data.data.flowList
      }) 
    },
    HandleEdit(data){
      var that = this
      that.ruleFormRef = true
      that.titleMath = '编辑订单'
      that.resetForm = 'baocun'  
      that.isRow = 1
      that.editId = data.id
      axios.get('/admin/order/order/read',{
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data);
        that.ruleForm.Row = res.data.data.row
        that.ruleForm.person = res.data.data.person_list
        that.ruleForm.unpaid = res.data.data.unpaid
        that.ruleForm.Row.username = res.data.data.row.customer_id
        that.Chanssa(res.data.data.row.customer_id)
        that.ruleForm.Row.goods_id = res.data.data.row.goodsone.goods_id
        that.ruleForm.Row.goods_price = res.data.data.row.goodsone.total_price
        that.ruleForm.Row.goods_num = res.data.data.row.goodsone.total_num
        //图片
        let fileArr = [];
        let fileObj = {};
        fileObj.name = "file";
        fileObj.url = res.data.data.row.imgs_url;
        fileArr.push(fileObj);
        that.fileList = fileArr
      }) 
     
    },
    BtnProcess(){   //进程提交
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/order/addflow', 
        data: {
          id:that.editId,
          row:{
            info: that.Info_desc, 
            images: that.Row_Eurl2
          }
        },
      }).then(function (res) {
        if(res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.showflow()
          that.Info_desc = ''
          that.fileList1 = []
        }
      })  
    },
    HandleRece(e,data){
      this.editId = data.id
      this.editVisible2 = true
      if(e == 1){
        this.editVis = 1
        this.Row1 = {}
      }else if(e == 2){
        this.editVis = 2
        this.time1 = data.order_no
        this.time2 = data.goodsone.goods_name
      }
    },
    saveEdit2(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/order/confirm', 
        data: {
          id: that.editId,
          row:{
            time: that.Row1.time, //服务周期
            type: that.Row1.type, //周期类型: 天  月
            realname: that.Row1.realname //用户姓名
          }
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else{
          that.$message.success(res.data.msg);
          that.editVisible2 = false
          that.loadTable()
        }
      })
    },
    saveEdit(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/order/transfer', 
        data: {
          id: that.editId,
          row:{
            business_id: that.staff_id, //服务周期
          }
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else{
          that.$message.success(res.data.msg);
          that.editVisible2 = false
          that.loadTable()
        }
      })
    },
    Handlupgrade(data){
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要升级吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'post',
          url: '/admin/order/order/upgrade',
          data: {
            ids: arr
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total = res.data.data.total
          that.$forceUpdate()
        })
      })
    },
    staff(){
      var that = this
      axios.get('/admin/order/order/statisticsColumns',{   // 全部员工列表
      }).then(function (res) {
        that.statisticsColumns = res.data.data
      }) 
      axios({ method: 'post', url: '/admin/ajax/goodsList', 
      }).then(function (res) {
        that.goodsList = res.data.data
      })
      axios.get('/admin/ajax/businessList',{   // 服务商
      }).then(function (res) {
        that.BusinessList = res.data.data
      })
      axios.get('/admin/ajax/siteList',{   // 站点列表    
      }).then(function (res) {
        that.SiteList = res.data.data
      }) 
    },
    HanDel(data) {  //删除
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
          axios({
          method: 'DELETE',
          url: '/admin/order/order/del',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    Selection(e) { //勾选
      var that = this
      console.log(e);
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    Add_Del(){
      var that = this
      if (that.multipleSelection_id == null) {      //批量删除
        that.$message.warning('请勾选信息');
      } else {
        that.$confirm("确定要删除吗？", "提示", {
          type: "primary"
      }).then(() => {
        axios({
          method: 'delete',
          url: '/admin/order/order/del',
          data: {
            ids: that.multipleSelection_id
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.loadTable()
            }
          }) 
        })
      }
    },
    Activ(e,id){
      if(e == 1){
        this.PayType_value = id
        this.search.pay_type = id
      }else if(e == 2){
        this.goodsList_value = id
        this.search.goods_id = id
      }else if(e == 3){
        this.Paymethod_value = id
        this.pay_form = id
      }
      this.loadTable()
    },
    Chanssa(e){
      let index = this.customerList.findIndex(obj => obj.id === e)
      this.ruleForm.Row.mobile = this.customerList[index].mobile
      this.valueB = this.customerList[index].source
      this.ruleForm.Row.customer_id = this.customerList[index].id
    },
    duixiang(){
      var that= this
      var arr = that.ruleForm.person
      if (arr == undefined) {
        var arr  = []
      }
      var att = {
         mobile:'',username:'',company:'',id_card:''
      }
      arr.push(att)
      that.ruleForm.person = arr
    },
    handleSuccess(e) {  //图片上传成功
      this.ruleForm.Row.images.push(e.data.url)
    },
    handleSuccess1(e){
      this.Row_Eurl2.push(e.data.url)
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    handleSelect2(key){
      if(key == 1){
        this.isAcndex = 1
      }else if(key == 2){
        this.isAcndex = 2
        this.showflow()
      }
    },
    Close(){
      this.ruleFormRef = false
      this.RetrieveId = ''
      this.ruleForm.Row.username = ''
      this.ruleForm.Row.mobile = ''
      this.valueB = ''
    }
  }
}
</script>
<style scoped>
.capitalLi{
  display: flex;
  /* flex-wrap: wrap; */
}
.NavLiIpt .el-input{
  max-width: 220px;
}
.NavLiIpt /deep/ .el-date-editor.el-input{
  min-width: 202px !important;
}
.gg{
  font-weight: bold;
  margin-bottom: 5px;
}
.AAA{
  width: 25%;
}
.AAA .el-input{
  width: 90%;
}
</style>